'use client'
import { useState } from 'react'
import { useTranslations } from 'next-intl'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import TeamModal from '@/app/components/Modals/TeamModal'
import Icon from '@/app/components/Ui/Icon'
import Accordion from './Accordion'

import styles from './TeamGroups.module.scss'

export default function TeamGroups({
  title,
  teams,
  attributes
}) {
  const t = useTranslations('buttons')
  const [activeIndexAccordion, setActiveIndexAccordion] = useState(0)
  const [isShowModal, setIsShowModal] = useState(false)
  const [modalData, setModalData] = useState()

  const onToggleActiveIndex = (index) => {
    setActiveIndexAccordion((prevIndex) => {
      if (index === prevIndex) {
        return null
      }
      return index
    })
  }

  const onOpenModal = (data) => {
    setIsShowModal(true)
    setModalData(data)
  }

  return (
    <div className={classNames(styles['team-groups'], `${attributes?.class || ''}`)} id={attributes?.id}>
      <div className='wr'>
        <div className={classNames(styles['team-groups_wrapper'], 'd-f al-fs')}>
          <div className={classNames(styles['team-groups_sidebar'])}>
            <h2 className={classNames(styles['team-groups_sidebar-title'], 'h3')}>{title}</h2>
            {Array.isArray(teams) && teams.length !== 0 && (
              <div className={classNames(styles['team-groups_sidebar-teams'])}>
                {teams.map((team, teamIndex) => (
                  <div
                    className={classNames(styles['team-groups_sidebar-teams-item'])}
                    key={teamIndex}
                  >
                    <button
                      className={classNames(
                        styles['team-groups_sidebar-btn'],
                        {
                          [styles['b-active']]: activeIndexAccordion === teamIndex
                        },
                        'h5'
                      )}
                      type="button"
                      onClick={() => setActiveIndexAccordion(teamIndex)}
                    >
                      {team.name}
                      <Icon
                        name='arrow'
                        width={20}
                        height={20}
                        viewBox="0 0 16 16"
                      />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={classNames(styles['team-groups_content'])}>
            {Array.isArray(teams) && teams.length !== 0 && (
              teams.map((team, teamIndex) => {
                return (
                  <Accordion
                    key={teamIndex}
                    onToggleOpen={() => onToggleActiveIndex(teamIndex)}
                    isOpen={activeIndexAccordion === teamIndex}
                    title={team.name}
                    isCategory
                  >
                    {Array.isArray(team.items) && team.items?.length !== 0 && (
                      <div className={classNames(
                        styles['team-groups_content-groups'],
                        'd-f ai-s fw-w',
                        {
                          [styles['b-hide-image']]: !team.isShowImage
                        }
                      )}
                      >
                        {team.items.map((item, grIndex) => (
                          <div key={`${teamIndex}-${grIndex}`} className={classNames(styles['team-groups_block'], 'd-f ai-fs fd-c')}>
                            {item.image && team.isShowImage && (
                              <div className={styles['team-groups_block-image']}>
                                <img src={item.image} alt={item.name} loading='lazy' />
                              </div>
                            )}
                            <div className={styles['team-groups_block-name']}>
                              {item?.lastName} {item?.firstName} {item?.surname}
                            </div>
                            <div className={styles['team-groups_block-position']}>
                              {item.position}
                            </div>
                            {item.isShowBtn && (
                              <button
                                className={styles['team-groups_block-btn-more']}
                                onClick={() => onOpenModal(item)}
                              >
                                {t('details')}
                              </button>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </Accordion>
                )
              })
            )}
            <CSSTransition
              in={isShowModal}
              timeout={400}
              unmountOnExit
              classNames="fade"
            >
              <TeamModal
                onCloseModal={() => setIsShowModal(null)}
                firstName={modalData?.firstName}
                lastName={modalData?.lastName}
                position={modalData?.position}
                image={modalData?.image}
                content={modalData?.content}
                description={modalData?.description}
                logo={modalData?.logo}
                title={modalData?.title}
                link={modalData?.link}
              />
            </CSSTransition>
          </div>
        </div>
      </div>
    </div>
  )
}

TeamGroups.propTypes = {
  title: PropTypes.string,
  teams: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    isShowImage: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      surname: PropTypes.string,
      isShowBtn: PropTypes.bool,
      position: PropTypes.string,
      content: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
      title: PropTypes.string,
      logo: PropTypes.string,
      image: PropTypes.string
    }))
  })),
  attributes: PropTypes.shape({
    class: PropTypes.string,
    id: PropTypes.string
  })
}