'use client'
import { useRef, useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from '@/app/components/Ui/Icon'

import styles from './Slider.module.scss'

export default function Slider({
  items,
  withTabs,
}) {
  const sliderContentRef = useRef()
  const [isDisabledUpBtn, setIsDisabledUpBtn] = useState(true)
  const [isDisabledDownBtn, setIsDisabledDownBtn] = useState(false)
  const [isShowButtons, setIsShowButtons] = useState(false)

  useEffect(() => {
    if (!sliderContentRef.current) {
      return false
    }
    setIsShowButtons(sliderContentRef.current.scrollHeight - sliderContentRef.current.offsetHeight > 0)
  }, [sliderContentRef])

  const onScrollUp = () => {
    if (!sliderContentRef.current) {
      return
    }
    const scrollTop = sliderContentRef.current.scrollTop - (sliderContentRef.current.offsetHeight - 100)
    sliderContentRef.current.scrollTop = scrollTop
    setIsDisabledDownBtn(false)
    setIsDisabledUpBtn(scrollTop <= 0)
  }
  const onScrollDown = () => {
    if (!sliderContentRef.current) {
      return
    }
    const scrollTop = sliderContentRef.current.scrollTop + (sliderContentRef.current.offsetHeight - 100)
    sliderContentRef.current.scrollTop = scrollTop
    setIsDisabledUpBtn(false)
    setIsDisabledDownBtn(sliderContentRef.current.offsetHeight + scrollTop >= sliderContentRef.current.scrollHeight)
  }

  return (
    <div
      className={classNames(
        styles['slider'],
        {
          [styles['with-btns']]: isShowButtons,
          [styles['with-tabs']]: withTabs,
          [styles['is-disabled-up']]: isShowButtons && isDisabledUpBtn,
          [styles['is-disabled-down']]: isShowButtons && isDisabledDownBtn,
        }
      )}
    >
      <div className={styles['slider_content']} ref={sliderContentRef}>
        {items.map((item, index) => (
          <div key={index} className={styles['slider_block']}>
            <div className={classNames(styles['slider_title'], 'h4')}>
              {item.title}
            </div>
            <div
              className={styles['slider_text']}
              dangerouslySetInnerHTML={{ __html: item.text }}
            />
          </div>
        ))}
      </div>
      {isShowButtons && (
        <div className={classNames(styles['slider_bottom'], 'd-f ai-c')}>
          <button
            onClick={onScrollUp}
            type='button'
            className={classNames(styles['slider_btn'], 'd-f ai-c jc-c')}
            disabled={isDisabledUpBtn}
          >
            <Icon
              name={'arrow-left'}
              width={25}
              height={25}
              viewBox="0 0 25 25"
            />
          </button>
          <button
            onClick={onScrollDown}
            type='button'
            className={classNames(styles['slider_btn'], 'd-f ai-c jc-c')}
            disabled={isDisabledDownBtn}
          >
            <Icon
              name={'arrow-right'}
              width={25}
              height={25}
              viewBox="0 0 25 25"
            />
          </button>
        </div>
      )}
    </div>
  )
}

Slider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string
  })),
  withTabs: PropTypes.bool
}
