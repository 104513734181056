'use client'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Accordion from '@/app/components/Ui/Accordion'

import styles from './Accordions.module.scss'

export default function Accordions({ items, isScrollToBlock = true, itemsTitleTag }) {
  const [activeItem, setActiveItem] = useState()

  const onToggleOpen = (index) => {
    setActiveItem((prevIndex) => {
      if (index === prevIndex) {
        return null
      }
      return index
    })
  }
  
  useEffect(() => {
    const searchParams = new URLSearchParams((window.location.href.split('?')?.[1] || ''))
    const accordionKey = searchParams.get('accordion')
    if (accordionKey && Array.isArray(items)) {
      const findIndex = items.findIndex((x) => x?.queryKey === accordionKey)
      if (findIndex >= 0) {
        onToggleOpen(findIndex)
      }
    }
  }, [])

  if (!Array.isArray(items)) {
    return
  }

  return (
    <div
      className={styles['accordions']}
    >
      {items.map((item, index) => (
        <Accordion
          key={index}
          onToggleOpen={() => onToggleOpen(index)}
          isOpen={activeItem === index}
          title={item.title}
          isScrollToBlock={isScrollToBlock}
          itemsTitleTag={itemsTitleTag}
        >
          {item.content}
        </Accordion>
      ))}
    </div>
  )
}

Accordions.propTypes = {
  defaultActiveIndex: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    queryKey: PropTypes.string,
    content: PropTypes.any
  })),
  isScrollToBlock: PropTypes.bool,
  itemsTitleTag: PropTypes.string,
}