import { IMAGE_PREFIX } from '@/app/constants/constants'

const iframeMediaOrigins = [
  'https://www.youtube.com',
  'https://player.vimeo.com',
  'https://open.spotify.com',
  'https://www.dailymotion.com',
]

export const changeImageSrc = (text) => {
  if (!text) {
    return text
  }

  const fixText = text.replace(/(srcset|sizes)="[^"]*"/g, (_, b) => '')

  return fixText.replace(/src="?([^"\s]+)"?\s*/g, (_, b) => {
    if (/^(http|https)/.test(b)) {
      const newUrl = new URL(b)

      if (!iframeMediaOrigins.includes(newUrl.origin)) {
        return `src="${IMAGE_PREFIX}${newUrl.pathname}"`
      }
    }

    if (b.startsWith('/')) {
      return `src="${IMAGE_PREFIX}${b}"`
    }

    return `src="${b}"`
  })
}