'use client'
import { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { handleScrollToElement } from '@/app/utils/handleScrollToElement'

import styles from './Accordion.module.scss'

export default function Accordion({ title, children, isOpen, onToggleOpen, isCategory }) {
  const timer = useRef(null);
  const itemRef = useRef()
  const contentRef = useRef()
  const [isInit, setIsInit] = useState(true)

  const onScrollToElement = (el) => {
    if (el) {
      const paginationBySections = document.getElementById('pagination-by-sections')
      const offset = paginationBySections ? paginationBySections.clientHeight : 0
      handleScrollToElement(el.parentNode, offset)
    }
  }

  useEffect(() => {
    if (!contentRef.current) {
      return
    }
    const scrollHeight = contentRef.current.scrollHeight

    clearTimeout(timer.current);

    if (isOpen) {
      contentRef.current.style.height = `${scrollHeight}px`
      timer.current = setTimeout(() => {
        if (isOpen) {
          contentRef.current.style.height = null

          if (!isInit) onScrollToElement(itemRef.current)
        }
      }, 300);
    } else {
      contentRef.current.style.height = `${scrollHeight}px`

      timer.current = setTimeout(() => {
        contentRef.current.style.height = '0px'
      }, 0);
    }

    setIsInit(false)

    return () => {
      clearTimeout(timer.current);
    };
  }, [contentRef, isOpen])

  return (
    <div
      className={classNames(
        styles['accordion'],
        isCategory && styles['is-category'],
        {
          [styles['b-open']]: isOpen
        }
      )}
    >
      <div
        ref={itemRef}
        className={classNames(styles['accordion_top'], 'd-f ai-c jc-sb')}
        onClick={onToggleOpen}
      >
        <div className={styles['accordion_title']}>
          {title}
        </div>
        <button className={styles['accordion_btn']} />
      </div>

      <div ref={contentRef} className={styles['accordion_content']} style={{height: "0px"}}>
        <div className={styles['accordion_inner']}>
          {children}
        </div>
      </div>
    </div>
  )
}

Accordion.propTypes = {
  onToggleOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any
}