'use client'
import { useRef, useState, useMemo, useEffect } from 'react'
import { useTranslations } from 'next-intl'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from '@/app/components/Ui/Icon'
import Link from '@/app/components/Ui/Link'

import styles from './News.module.scss'
import NewsBlock from './NewsBlock'

let clientPositionX = 0

export default function News({
  title,
  link,
  linkText,
  categories = [],
  recentlyPosts,
  attributes
}) {
  const t = useTranslations('news')
  const defaultCategory = {
    name: t('allPosts'),
    slug: 'all',
    posts: recentlyPosts
  }
  const [activeCategory, setActiveCategory] = useState(defaultCategory)
  const [isMouseDown, setIsMouseDown] = useState(false)

  const activeItems = useMemo(() => {
    if (Array.isArray(categories) && categories.length === 1) {
      return Array.isArray(categories?.[0].posts) ? categories?.[0].posts : []
    }
    return Array.isArray(activeCategory.posts) ? activeCategory.posts : []
  }, [activeCategory])

  const localCategories = useMemo(() => {
    if (Array.isArray(categories)) {
      return [
        defaultCategory,
        ...categories
      ]
    }
    return []
  }, [categories])

  const categoriesRef = useRef()

  const onChangeCategory = (category) => {
    setActiveCategory(category)
    scrollToElement(category)
  }

  const scrollToElement = (item) => {
    const children = categoriesRef.current?.children
    if (children?.length && localCategories?.length) {
      const index = localCategories.findIndex((i) => i.slug === item.slug)
      const offsetLeft = children?.[index]?.offsetLeft
      categoriesRef.current.style.scrollBehavior = 'smooth'
      categoriesRef.current.scrollLeft = offsetLeft || 0
    }
  }

  const onMouseDown = (e) => {
    setIsMouseDown(true)
    categoriesRef.current.style.scrollBehavior = 'auto'
    clientPositionX = categoriesRef.current.scrollLeft + e.clientX
  }

  const onMouseMove = (e) => {
    if (!isMouseDown) return
    categoriesRef.current.scrollLeft = clientPositionX - e.clientX
  }

  const onMouseUp = () => {
    setIsMouseDown(false)
  }

  useEffect(() => {
    const searchParams = new URLSearchParams((window.location.href.split('?')?.[1] || ''))
    const categoryKey = searchParams.get('categoryTab')
    if (categoryKey) {
      const findCategory = localCategories.find((x) => x?.slug === categoryKey)
      if (findCategory?.slug && findCategory?.slug !== activeCategory?.slug) {
        onChangeCategory(findCategory)
      }
    }
  }, [])

  return (
    <div className={classNames(styles['news'], `${attributes?.class || ''}`)} id={attributes?.id}>
      <div className='wr'>
        <div className={classNames(styles['news_title'], 'news_title h2')}>{title}</div>
        {Array.isArray(categories) && categories.length > 1 && (
          <div className={classNames(styles['news_top'], 'd-f ai-c jc-sb')}>
            <div
              className={classNames(styles['news_categories'], 'd-f ai-c')}
              ref={categoriesRef}
              onMouseDown={onMouseDown}
              onMouseMove={onMouseMove}
              onMouseUp={onMouseUp}
              onMouseLeave={onMouseUp}
            >
              {localCategories?.length > 1 && localCategories.map((category) => (
                <div
                  key={category.slug}
                  onClick={() => onChangeCategory(category)}
                  className={classNames(
                    styles['news_categories-item'],
                    {
                      [styles['b-active']]: category.slug === activeCategory?.slug
                    }
                  )}
                >
                  {category.name}
                </div>
              ))}
            </div>
            {link && linkText && (
              <Link href={link} className={classNames(styles['news_link-arrow'], 'link-arrow')}>
                {linkText}
                <Icon
                  name='arrow'
                  width={13}
                  height={14}
                  viewBox="0 0 16 16"
                />
              </Link>
            )}
          </div>
        )}
        {Array.isArray(activeItems) && activeItems.length !== 0 ? (
          <div className={classNames(styles['news_content'], 'd-f ai-s fw-w')}>
            {activeItems.map((item, index) => (
              <NewsBlock
                key={index}
                link={item.link}
                previewImage={item.previewImage}
                text={item.text}
                categoryName={item.categoryName}
                date={item.localDate}
              />
            ))}
          </div>
        ) : (
          <div className={classNames(styles['news_not-found'], 'rich-text')}>
            <div className='h5'>
              {t('noInformation')}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

News.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    posts: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      categoryName: PropTypes.string,
      previewImage: PropTypes.string,
      link: PropTypes.string,
      date: PropTypes.string
    }))
  })),
  recentlyPosts: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    categoryName: PropTypes.string,
    previewImage: PropTypes.string,
    link: PropTypes.string,
    date: PropTypes.string
  })),
  attributes: PropTypes.shape({
    class: PropTypes.string,
    id: PropTypes.string
  })
}