'use client'
import { useState } from 'react'
import { useTranslations } from 'next-intl'
import classNames from 'classnames'
import ReactSelect from 'react-select'
import PropTypes from 'prop-types'

export default function Select({
  selectedOption,
  onChange,
  isLoading,
  options = [],
  placeholder,
  className,
  ...props
}) {
  const t = useTranslations('select')
  const [isFocus, setIsFocus] = useState(false)

  return (
    <div className='form_item'>
      <ReactSelect
        onChange={onChange}
        value={selectedOption}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        placeholder={' '}
        options={options}
        isLoading={isLoading}
        classNamePrefix="select"
        noOptionsMessage={() => t('noOptionsMessage')}
        loadingMessage={() => t('loadingMessage')}
        className={classNames(
          'select',
          className,
          {
            'b-has-value': selectedOption || isFocus
          }
        )}
        {...props}
      />
      {placeholder && (
        <label className='form_placeholder'>
          {placeholder}
        </label>
      )}
    </div>
  )
}

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  selectedOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  className: PropTypes.string
}