'use client'
import { useId, useMemo } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useController } from 'react-hook-form'
import Icon from '@/app/components/Ui/Icon'

import styles from './FormFile.module.scss'

export default function FormFile({
  control,
  name,
  exactId,
  validation = {},
  errors = {},
  label,
  accept
}) {
  const uniqueId = useId()
  const {
    field
  } = useController({
    name,
    control,
    rules: {
      ...validation
    }
  })
  const fileNames = useMemo(() => {
    if (typeof field?.value === 'object' && field?.value.length !== 0) {
      return Array.from(field?.value).map(i => i.name)
    }
    return []
  }, [field])

  const onDeleteFile = (index) => {
    const newValue = field.value.filter((_, i) => i !== index)
    field.onChange(newValue)
  }

  const getId = () => {
    return exactId || `form-${name || 'file'}-${uniqueId}`
  }

  return (
    <div
      className={classNames(
        styles['form-file'],
        {
          [styles['b-error']]: errors?.[name]
        },
        'form-file'
      )}
    >
      <input
        onChange={(e) => {
          if (e?.target?.files?.length !== 0) {
            field.onChange([...e.target.files, ...(field.value || [])])
          }
        }}
        multiple={true}
        onBlur={field.onBlur}
        name={field.name}
        id={getId()}
        className={styles['form-file_input']}
        type='file'
        accept={accept || null}
      />
      <label
        htmlFor={getId()}
        className={classNames(styles['form-file_label'], 'd-if ai-fs')}
      >
        <Icon
          className={styles['form-file_label-icon']}
          name={'document'}
          width={49}
          height={48}
          viewBox="0 0 49 48"
        />
        <span className={styles['form-file_label-text']}>
          { label }
        </span>
      </label>
      {Array.isArray(fileNames) && fileNames.length !== 0 && fileNames.map((file, index) => (
        <div className={styles['form-file_name']} key={`${file}-${index}`}>
          {file}
          <button
            className={classNames(styles['form-file_btn'], 'd-f ai-c jc-c')}
            onClick={() => onDeleteFile(index)}
            type='button'
          >
            <Icon
              name={'cross'}
              width={16}
              height={16}
              viewBox="0 0 16 16"
            />
          </button>
        </div>
      ))}
      {errors?.[name]?.message && <div className='form_error-text'>{errors?.[name]?.message}</div>}
    </div>
  )
}

FormFile.propTypes = {
  control: PropTypes.any,
  name: PropTypes.string,
  exactId: PropTypes.string,
  placeholder: PropTypes.string,
  validation: PropTypes.object,
  errors: PropTypes.object,
  accept: PropTypes.string
}