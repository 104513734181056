'use client'
import { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Breadcrumbs from '@/app/components/Ui/Breadcrumbs'
import Icon from '@/app/components/Ui/Icon'
import Link from '@/app/components/Ui/Link'
import Accordion from './Accordion'

import styles from './ServiceContent.module.scss'

export default function ServiceContent({
  tabs,
  breadcrumbs,
  attributes
}) {
  const [activeIndexAccordion, setActiveIndexAccordion] = useState(0)

  const onToggleActiveIndex = (index) => {
    setActiveIndexAccordion((prevIndex) => {
      if (index === prevIndex) {
        return null
      }
      return index
    })
  }

  return (
    <div
      className={classNames(
        styles['service-content'],
        `${attributes?.class || ''}`
      )}
      id={attributes.id}
    >
      <Breadcrumbs items={breadcrumbs} />
      <div className='wr'>
        <div className={classNames(styles['service-content_wrapper'], 'd-f al-fs')}>
          <div className={classNames(styles['service-content_sidebar'])}>
            {Array.isArray(tabs) && tabs.length !== 0 && tabs.map((team, teamIndex) => (
              <div
                className={classNames(styles['service-content_sidebar-item'])}
                key={teamIndex}
              >
                <button
                  className={classNames(
                    styles['service-content_sidebar-btn'],
                    activeIndexAccordion === teamIndex && styles['b-active'],
                    'h5 d-f ai-c'
                  )}
                  type="button"
                  onClick={() => setActiveIndexAccordion(teamIndex)}
                >
                  {team.name}
                  <Icon
                    name='arrow'
                    width={20}
                    height={20}
                    viewBox="0 0 16 16"
                  />
                </button>
              </div>
            ))}
          </div>
          <div className={classNames(styles['service-content_content'])}>
            {Array.isArray(tabs) && tabs.length !== 0 && (
              <>
                {tabs.map((tab, tabIndex) => {
                  return (
                    <Accordion
                      key={tabIndex}
                      onToggleOpen={() => onToggleActiveIndex(tabIndex)}
                      isOpen={activeIndexAccordion === tabIndex}
                      title={tab.name}
                      isCategory
                    >
                      <h1 className={classNames(styles['service-content_title'], 'h3')}>{tab.title}</h1>
                      {tab.text && (
                        <div className={classNames(styles[''], 'rich-text')} dangerouslySetInnerHTML={{ __html: tab.text }} />
                      )}
                      {Array.isArray(tab.items) && tab.items?.length !== 0 && (
                        <div className={classNames(styles['service-content_payments'], 'd-f ai-s fw-w')}>
                          {tab.items.map((item, contentIndex) => (
                            <div key={`${tabIndex}-${contentIndex}`} className={classNames(styles['service-content_block'], 'd-f ai-fs jc-fs fd-c')}>
                              <div className={styles['service-content_block-logo']}>
                                <img src={item.logo} alt='logo' loading='lazy' />
                              </div>
                              <Link
                                href={item.link}
                                isOpenNewTab={item.isOpenNewTab}
                                className={classNames(
                                  styles['service-content_block-link'],
                                  'btn b-with-icon',
                                  {
                                    'bg-bordered': tab.backgroundButton === 'white'
                                  }
                                )}
                                target='_blank'
                              >
                                {item.linkText}
                                <Icon
                                  name='arrow-link'
                                  width={12}
                                  height={13}
                                  viewBox="0 0 12 13"
                                />
                              </Link>
                            </div>
                          ))}
                        </div>
                      )}
                    </Accordion>
                  )
                })}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

ServiceContent.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    backgroundButton: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      linkText: PropTypes.string,
      isOpenNewTab: PropTypes.any,
      link: PropTypes.string,
      logo: PropTypes.string
    }))
  })),
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string
  })),
  attributes: PropTypes.shape({
    class: PropTypes.string,
    id: PropTypes.string
  })
}