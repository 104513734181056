"use client"
import PropTypes from "prop-types"
import { useMemo } from "react"
import classNames from "classnames"
import { Tooltip } from "react-tooltip"
import Button from "@/app/components/Ui/Button"
import Icon from "@/app/components/Ui/Icon"

import styles from "./PackagesBlock.module.scss"

export default function PackagesBlock({
  infoData,
  head,
  bottom,
  list,
  hasRecommendText,
  background,
  onOpenFormModal
}) {
  const headButtonOptions = useMemo(() => {
    if (head?.button) {
      return {
        type: head.button.startsWith('aiwaType') ? 'aiwa' : 'form',
        value: head.button.match(/\[(.*?)\]/)?.[1]
      }
    }
    return null
  }, [head])
  const bottomButtonOptions = useMemo(() => {
    if (bottom?.button) {
      return {
        type: bottom.button.startsWith('aiwaType') ? 'aiwa' : 'form',
        value: bottom.button.match(/\[(.*?)\]/)?.[1]
      }
    }
    return null
  }, [bottom])

  return (
    <div
      className={classNames(
        styles["packages-block"],
        {
          [styles[`b-${background || 'default'}`]]: head?.recommend,
          [styles['b-recommend']]: head?.recommend,
          [styles['b-has-recommend-text']]: hasRecommendText
        }
      )}
    >
      {head && (
        <div
          className={classNames(
            styles["packages-block-head"],
            "js-packages-item d-f fd-c jc-fs ai-c"
          )}
        >
          {head.headText && (
            <div className={styles["packages-block-recommend-text"]}>
              {head.headText}
            </div>
          )}
          <div className={styles["packages-block-name"]}>
            {head.name}
          </div>
          {head.text && (
            <div className={styles["packages-block-text"]}>
              {head.text}
            </div>
          )}
          <div className={styles["packages-block-head-bottom"]}>
            <div className={styles["packages-block-price"]}>
              {head.price}
            </div>
            <div className={styles["packages-block-btn"]}>
              {headButtonOptions?.type === 'form' && (
                <Button
                  theme={head?.recommend ? 'default' : 'bordered'}
                  onClick={() => onOpenFormModal(headButtonOptions.value)}
                >
                  {head.buttonText}
                </Button>
              )}
              {headButtonOptions?.type === 'aiwa' && (
                <div
                  className="aiwa-element"
                  data-aiwa-type={headButtonOptions.value}
                  data-aiwa-mode="button"
                  dangerouslySetInnerHTML={{
                    __html: `
                      <template class="aiwa-template-button">
                        <button class="btn bg-${head?.recommend ? 'default' : 'bordered'}">
                          ${head.buttonText}
                        </button>
                      </template>
                    `}}
                >
                </div>
              )}
            </div>
            {head.textUnderButton && (
              <div className={styles["packages-block-text"]}>
                {head.textUnderButton}
              </div>
            )}
          </div>
        </div>
      )}
      {!!list?.length && list.map((item, i) => {
        const infoItemData = infoData?.[i + 1] // first item use for head
        return (
          (
            <div
              key={i}
              className={classNames(
                styles["packages-block-item"],
                "js-packages-item d-f ai-c jc-c"
              )}
            >
              {(infoItemData?.title || infoItemData?.text) && (
                <div className={styles['packages-block-info']}>
                  {infoItemData.title && (
                    <div className={styles["packages-block-info-title"]}>
                      {infoItemData.title}
                      {infoItemData.tooltipText && (
                        <>
                          {" "}
                          <Tooltip
                            className={styles['packages-block-info-tooltip']}
                            id={`packages-block-info-${i}`}
                            place="bottom-start"
                            content={infoItemData.tooltipText}
                            noArrow
                            openOnClick
                            disableStyleInjection
                          />
                          <Icon
                            data-tooltip-id={`packages-block-info-${i}`}
                            className={styles["packages-block-info-icon"]}
                            name={"info-2"}
                            width={20}
                            height={20}
                            viewBox="0 0 24 24"
                          />
                        </>
                      )}
                    </div>
                  )}
                  {infoItemData.text && (
                    <div className={styles["packages-block-info-text"]}>
                      {infoItemData.text}
                    </div>
                  )}
                </div>
              )}
              <div className={styles["packages-block-value"]}>
                {item.text && <span dangerouslySetInnerHTML={{
                  __html: item.text
                }} />}
                {item.icon === 'cross' && (
                  <Icon
                    className={styles["packages-block-icon"]}
                    name={"close"}
                    width={16}
                    height={16}
                    viewBox="0 0 21 21"
                  />
                )}
                {item.icon === 'check' && (
                  <Icon
                    className={styles["packages-block-icon"]}
                    name={"check"}
                    width={17}
                    height={16}
                    viewBox="0 0 17 16"
                  />
                )}
                {!item.text && !item.icon && (
                  <Icon
                    className={styles["packages-block-icon"]}
                    name={"zoom-minus"}
                    width={16}
                    height={16}
                    viewBox="0 0 20 20"
                  />
                )}
              </div>
            </div>
          )
        )
      })}
      {bottom && (
        <div
          className={classNames(
            styles["packages-block-bottom"],
            "js-packages-item"
          )}
        >
          {bottom.text && (
            <div className={styles["packages-block-text"]}>
              {bottom.text}
            </div>
          )}
          {bottom.price && (
            <div className={styles["packages-block-price"]}>
              {bottom.price}
            </div>
          )}
          {bottom.buttonText && (
            <div className={styles["packages-block-btn"]}>
              {bottomButtonOptions?.type === 'form' && (
                <Button
                  theme={head?.recommend ? 'default' : 'bordered'}
                  onClick={() => onOpenFormModal(bottomButtonOptions.value)}
                >
                  {bottom.buttonText}
                </Button>
              )}
              {bottomButtonOptions?.type === 'aiwa' && (
                <div
                  className="aiwa-element"
                  data-aiwa-type={bottomButtonOptions.value}
                  data-aiwa-mode="button"
                  dangerouslySetInnerHTML={{
                    __html: `
                    <template class="aiwa-template-button">
                      <button class="btn bg-${head?.recommend ? 'default' : 'bordered'}">
                        ${bottom.buttonText}
                      </button>
                    </template>
                  `}}
                >
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

PackagesBlock.propTypes = {
  infoData: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    tooltipText: PropTypes.string,
    text: PropTypes.string
  })),
  head: PropTypes.shape({
    name: PropTypes.string,
    text: PropTypes.string,
    button: PropTypes.string,
    buttonText: PropTypes.string,
    recommend: PropTypes.bool,
    textUnderButton: PropTypes.string,
    headText: PropTypes.string,
    price: PropTypes.string
  }),
  list: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    icon: PropTypes.string
  })),
  bottom: PropTypes.shape({
    text: PropTypes.string,
    button: PropTypes.string,
    buttonText: PropTypes.string,
    price: PropTypes.string
  }),
  hasRecommendText: PropTypes.bool,
  background: PropTypes.string,
  onOpenFormModal: PropTypes.func
}
