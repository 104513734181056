'use client'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useTranslations } from 'next-intl'
import { changeImageSrc } from '@/app/utils/changeImageSrc'
import AnimateHeight from 'react-animate-height'

import styles from './SeoText.module.scss'

export default function SeoText({
  text,
  attributes,
}) {
  const t = useTranslations('pagination')
  const [isShowText, setIsShowText] = useState(false)

  const handleToggleText = () => {
    setIsShowText(true)
  }

  return (
    <div
      className={classNames(
        styles['seo-text'],
        `${attributes?.class || ''}`
      )}
      id={attributes?.id}
    >
      <div className='wr'>
        <AnimateHeight
          className={styles['seo-text__text']}
          duration={300}
          height={isShowText ? 'auto' : 150}
          easing={'cubic-bezier(0.4, 0, 0.2, 1)'}
        >
          <div
            itemProp='description'
            className='rich-text'
            dangerouslySetInnerHTML={{ __html: changeImageSrc(text)  }}
          />
        </AnimateHeight>

        {!isShowText && (
          <button
            className={classNames(styles['seo-text__btn'], 'btn')}
            type='button'
            onClick={handleToggleText}
          >
            {t('readMore')}
          </button>
        )}
      </div>
    </div>
  )
}

SeoText.propTypes = {
  text: PropTypes.string,
  attributes: PropTypes.shape({
    class: PropTypes.string,
    id: PropTypes.string
  })
}
