'use client'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'

import styles from './Accordion.module.scss'

export default function Accordion({ title, children, isOpen, onToggleOpen, isCategory }) {

  return (
    <div
      className={classNames(
        styles['accordion'],
        isCategory && styles['is-category'],
        {
          [styles['b-open']]: isOpen
        }
      )}
    >
      <div
        className={classNames(styles['accordion_top'], 'd-f ai-c jc-sb')}
        onClick={onToggleOpen}
      >
        <div className={styles['accordion_title']}>
          {title}
        </div>
        <button className={styles['accordion_btn']} />
      </div>
      <CSSTransition
        in={isOpen}
        timeout={300}
        onEntered={(e) => {
          window.scrollTo({top: e.parentNode.offsetTop - 80})
        }}
        onEnter={(e) => {
          e.style.height = `${e.scrollHeight}px`
          window.scrollTo({top: e.parentNode.offsetTop - 80})
        }}
        onExit={(e) => {
          e.style.height = `0px`
        }}
      >
        <div className={styles['accordion_content']}>
          <div className={styles['accordion_inner']}>
            {children}
          </div>
        </div>
      </CSSTransition>
    </div>
  )
}

Accordion.propTypes = {
  onToggleOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any
}