'use client'
import { useTranslations } from 'next-intl'
import { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { handleScrollToElement } from '@/app/utils/handleScrollToElement'
import Icon from '@/app/components/Ui/Icon'
import DocumentLink from '@/app/components/Ui/DocumentLink'
import Accordion from './Accordion'

import styles from './DocumentGroups.module.scss'

export default function DocumentGroups({
  title,
  hideGroupTitle,
  isShowSearch,
  categories,
  attributes
}) {
  const t = useTranslations('form')
  const contentRef = useRef()
  const [query, setQuery] = useState('')
  const [activeCategory, setActiveCategory] = useState(0)
  const [activeGroup, setActiveGroup] = useState()
  const [result, setResult] = useState(categories)

  const onToggleCategory = (index) => {
    setActiveCategory((prevIndex) => {
      if (index === prevIndex) {
        return null
      }
      return index
    })
  }

  const onToggleGroup = (index, disabled) => {
    if (disabled) return
    setActiveGroup((prevIndex) => {
      if (index === prevIndex) {
        return null
      }
      return index
    })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams((window.location.href.split('?')?.[1] || ''))
    const categoryKey = searchParams.get('groupTab')
    const groupKey = searchParams.get('group')
    if (groupKey) {
      let categoryIndex
      let groupIndex
      let isDisabled
      result.forEach((category, index) => {
        const findGroupIndex = category.groups.findIndex((group) => group.queryKey === groupKey)
        if (findGroupIndex >= 0) {
          categoryIndex = index
          groupIndex = findGroupIndex
          isDisabled = category.groups[findGroupIndex].isDisabled
        }
      })
      if (categoryIndex >= 0 && groupIndex >= 0) {
        setActiveCategory(categoryIndex)
        onToggleGroup(`${categoryIndex}-${groupIndex}`, isDisabled)
        return
      }
    }
    if (categoryKey) {
      const findIndexCategory = result.findIndex((x) => x?.queryKey === categoryKey)
      if (findIndexCategory >= 0 && findIndexCategory !== activeCategory) {
        setActiveCategory(findIndexCategory)
      } else {
        // scroll to the first grout as the state does not change
        if (findIndexCategory === 0 && contentRef.current) {
          handleScrollToElement(contentRef.current)
        }
        setActiveCategory(0)
      }
    }
  }, [])

  useEffect(() => {
    if (!query) return setResult(categories)
    setResult(categories.reduce((accumulator, category) => {
      const filteredGroups = category.groups.reduce((groupAccumulator, group) => {
        const filteredDocs = group.documents.filter((doc) => doc.name.toLowerCase().includes(query.toLowerCase()))

        if (filteredDocs.length) {
          groupAccumulator.push({ name: group.name, documents: filteredDocs })
        }

        return groupAccumulator
      }, [])

      if (filteredGroups.length) {
        accumulator.push({ name: category.name, index: category.index, groups: filteredGroups })
      }

      return accumulator
    }, []))
    setActiveCategory(0)
    setActiveGroup()
  }, [query])

  return (
    <div className={classNames(styles['document-groups'], `${attributes?.class || ''}`)} id={attributes.id}>
      <div className='wr'>
        {title && (
          <h2 className={styles['document-groups_title']}>{title}</h2>
        )}
        <div className={classNames(styles['document-groups_wrapper'], 'd-f')}>
          <div className={classNames(styles['document-groups_sidebar'])}>
            {isShowSearch && (
              <div className={classNames(styles['document-groups_sidebar-search'])}>
                <Icon
                  name={'search'}
                  width={24}
                  height={24}
                  viewBox="0 0 25 25"
                />
                <input
                  value={query}
                  onChange={e => setQuery(e.target.value)}
                  placeholder={t('search')}
                />
              </div>
            )}

            {Array.isArray(categories) && categories.length !== 0 && (
              <div className={classNames(styles['document-groups_sidebar-categories'])}>
                {result.map((category, catIndex) => (
                  <div
                    className={classNames(styles['document-groups_sidebar-categories-item'])}
                    key={catIndex}
                  >
                    <button
                      className={classNames(
                        activeCategory === catIndex && styles['active'],
                        'h5'
                      )}
                      type="button"
                      onClick={() => setActiveCategory(catIndex)}
                    >
                      <span>{category.name}</span>
                      <Icon
                        name='arrow'
                        width={20}
                        height={20}
                        viewBox="0 0 16 16"
                      />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div ref={contentRef} className={classNames(styles['document-groups_content'])}>
            {Array.isArray(categories) && categories.length !== 0 && (
              <div className={classNames(styles['document-groups_content-categories'])}>
                {result.map((category, catIndex) => (
                  <Accordion
                    key={catIndex}
                    onToggleOpen={() => onToggleCategory(catIndex)}
                    isOpen={activeCategory === catIndex}
                    title={category.name}
                    isCategory
                  >
                    {!hideGroupTitle && (
                      <div className={classNames(styles['document-groups_content-categories-title'], 'h3')}>{category.name}</div>
                    )}

                    {Array.isArray(category.groups) && category.groups?.length !== 0 && (
                      <div className={classNames(styles['document-groups_content-groups'])}>
                        {category.groups.map((group, grIndex) => (
                          <Accordion
                            key={`${catIndex}-${grIndex}`}
                            onToggleOpen={() => onToggleGroup(`${catIndex}-${grIndex}`, group.isDisabled)}
                            isOpen={activeGroup === `${catIndex}-${grIndex}`}
                            title={group.name}
                          >
                            {group.description && (
                              <div
                                className={classNames(styles['documents_descr'], 'ws-pl')}
                                dangerouslySetInnerHTML={{ __html: group.description }}
                              />
                            )}

                            {Array.isArray(group.documents) && group.documents?.length !== 0 && (
                              <div className={classNames(styles['documents_content'], 'd-f ai-s fw-w')}>
                                {group.documents.map((item, index) => (
                                  <DocumentLink
                                    key={`${catIndex}-${grIndex}-${index}`}
                                    link={item.link}
                                    name={item?.name}
                                    ext={item?.ext}
                                  />
                                ))}
                              </div>
                            )}
                          </Accordion>
                        ))}
                      </div>
                    )}
                  </Accordion>
                ))}
              </div>
            )}
          </div>
        </div>

        {query && Array.isArray(categories) && categories.length !== 0 && !result.length && (
          <p>Нічого не знайдено</p>
        )}
      </div>
    </div>
  )
}

DocumentGroups.propTypes = {
  title: PropTypes.string,
  hideGroupTitle: PropTypes.bool,
  isShowSearch: PropTypes.bool,
  categories: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.number,
    queryKey: PropTypes.string,
    name: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.shape({
      index: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      queryKey: PropTypes.string,
      isDisabled: PropTypes.bool,
      documents: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
        ext: PropTypes.string
      }))
    }))
  })),
  attributes: PropTypes.shape({
    class: PropTypes.string,
    id: PropTypes.string
  })
}