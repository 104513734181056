'use client'
import { useState, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Tabs.module.scss'

let clientPositionX = 0

export default function Tabs({ children, onClickTab }) {
  const [activeTab, setActiveTab] = useState(children?.[0]?.props?.tabKey)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const headRef = useRef()

  const activeContent = useMemo(() => {
    return (children || []).find((item) => item.props.tabKey === activeTab)?.props?.children
  }, [activeTab, children])

  const onClickTabItem = (tab, index) => {
    if (!tab) {
      return
    }
    setActiveTab(tab)
    scrollToElement(index)
    if (typeof onClickTab === 'function') {
      onClickTab(tab, index)
    }
  }

  const scrollToElement = (index) => {
    const _children = headRef.current?.children
    if (_children?.length) {
      const offsetLeft = _children?.[index]?.offsetLeft
      headRef.current.style.scrollBehavior = 'smooth'
      headRef.current.scrollLeft = offsetLeft || 0
    }
  }

  const onMouseDown = (e) => {
    setIsMouseDown(true)
    headRef.current.style.scrollBehavior = 'auto'
    clientPositionX = headRef.current.scrollLeft + e.clientX
  }

  const onMouseMove = (e) => {
    if (!isMouseDown) return
    headRef.current.scrollLeft = clientPositionX - e.clientX
  }

  const onMouseUp = () => {
    setIsMouseDown(false)
  }

  return (
    <div className={styles['tabs']}>
      <div
        className={classNames(styles['tabs_head'], 'd-f ai-c fw-n')}
        ref={headRef}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseLeave={onMouseUp}
      >
        {(children || []).map((child, index) => (
          <div
            key={index}
            className={classNames(
              styles['tabs_head-item'],
              {
                [styles['b-active']]: child.props.tabKey === activeTab
              }
            )}
            onClick={() => onClickTabItem(child.props.tabKey, index)}
          >
            {child.props.label}
          </div>
        ))}
      </div>
      <div className={classNames(styles['tabs_content'], 'tabs_content')}>
        {activeContent}
      </div>
    </div>
  )
}

Tabs.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired,
  onClickTab: PropTypes.func
}