'use client'
import { useState, useMemo, useRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Tabs from '@/app/components/Ui/Tabs'
import Tab from '@/app/components/Ui/Tab'
import Icon from '@/app/components/Ui/Icon'
import FormBuilder from '@/app/components/Ui/FormBuilder'

import { handleScrollToElement } from '@/app/utils/handleScrollToElement'

import styles from './Feedback.module.scss'

let timeout

export default function Feedback({
  tabs,
  attributes
}) {
  const formWrapperRef = useRef()
  const [activeTab, setActiveTab] = useState(tabs?.[0])
  const contactHasHash = useMemo(
    () => Array.isArray(activeTab?.contacts) && activeTab?.contacts.length > 1,
    [activeTab?.contacts]
  )

  const onChangeActiveTab = (_, index) => {
    setActiveTab(tabs?.[index])
    if (window.innerWidth <= 1024) {
      clearTimeout(timeout)
      // const root = document.getElementsByTagName('html')?.[0]
      // root.style.scrollBehavior = 'auto'
      timeout = setTimeout(() => {
        handleScrollToElement(formWrapperRef.current)
        // root.style.scrollBehavior = 'smooth'
      }, 300)
    }
  }

  if (!Array.isArray(tabs) && tabs.length === 0) {
    return null
  }

  return (
    <div className={classNames(styles['feedback'], `${attributes?.class || ''}`)} id={attributes?.id}>
      <div className='wr'>
        <div className={classNames(
          styles['feedback_wrapper'],
          'd-f ai-s fw-w jc-sb',
          {
            [styles['b-has-hash']]: contactHasHash
          }
        )}
        >
          <div className={classNames(styles['feedback_left'], 'd-f ai-fs jc-fs fd-c')}>
            {activeTab?.image && (
              <div className={classNames(styles['feedback_image'], styles['b-mobile'], 'b-image-filter')}>
                <img src={activeTab.image} alt={activeTab?.title} width='257' height='287' loading='lazy' />
              </div>
            )}
            <div className='h2'>{activeTab?.title}</div>
            {activeTab?.text && (
              <div
                className={styles['feedback_text']}
                dangerouslySetInnerHTML={{ __html: activeTab?.text || '' }}
              />
            )}
            {activeTab?.description && (
              <div
                className={styles['feedback_description']}
                dangerouslySetInnerHTML={{ __html: activeTab.description }}
              />
            )}
            <div className={classNames(styles['feedback_bottom'], 'd-f ai-fe jc-sb')}>
              {((Array.isArray(activeTab?.contacts) && activeTab.contacts.length !== 0) || (Array.isArray(activeTab?.socialLinks) && activeTab.socialLinks.length !== 0)) && (
                <div className={styles['feedback_contact']}>
                  {Array.isArray(activeTab?.contacts) && activeTab.contacts.length !== 0 && activeTab.contacts.map((item, index) => {
                    const link = item.type === 'phone' ? `tel:${item.value}` : `mailto:${item.value}`
                    return (
                      <div key={index} className={classNames(styles['feedback_contact-item'], 'd-f ai-c fw-w')}>
                        {item.label && <div className={styles['feedback_contact-label']}>{item.label}</div>}
                        <a href={link} className={styles['feedback_contact-value']}>
                          {item.type === 'phone' ? (
                            <Icon
                              name='phone-blue'
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              className={styles['feedback_contact-icon']}
                            />
                          ) : (
                            <Icon
                              name='email'
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              className={styles['feedback_contact-icon']}
                            />
                          )}
                          {item.value}
                        </a>
                        {item.hash && <div className={styles['feedback_contact-hash']}>{item.hash}</div>}
                      </div>
                    )
                  })}

                  {Array.isArray(activeTab?.socialLinks) && activeTab.socialLinks.length !== 0 && (
                    <div className={styles['feedback_socials']}>
                      {activeTab.socialTitle && <div className={styles['feedback_socials-title']}>{activeTab.socialTitle}</div>}

                      <div
                        className={classNames(
                          styles['feedback_socials-list'],
                          'd-f ai-c fw-w',
                          activeTab?.socialLinksStyle && styles[`feedback_socials-list--item-${activeTab?.socialLinksStyle}`]
                        )}
                      >
                        {activeTab?.socialLinks.map((social, index) => (
                          <a
                            key={index}
                            href={social.link}
                            target='_blank'
                            rel='nofollow'
                            className={classNames(
                              styles['feedback_socials-list-item'],
                              'd-f ai-c jc-c'
                            )}
                          >
                            <img src={social.icon} width='24' height='24' loading='lazy' />
                          </a>
                        ))}

                        {activeTab.socialValue && (
                          <div
                            className={classNames(
                              styles['feedback_socials-list-value'],
                              'h5'
                            )}
                          >
                            {activeTab.socialValue}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {activeTab?.image && (
                <div className={classNames(styles['feedback_image'], 'b-image-filter')}>
                  <img src={activeTab.image} alt={activeTab?.title} width='257' height='287' loading='lazy' />
                </div>
              )}
            </div>
          </div>
          <div className={styles['feedback_right']} ref={formWrapperRef}>
            {tabs.length > 1 ? (
              <Tabs onClickTab={onChangeActiveTab}>
                {tabs.map((tab, index) => (
                  <Tab
                    key={index}
                    label={tab.tabName}
                    tabKey={`tab-form-${index}`}
                  >
                    {tab.form && (
                      <FormBuilder
                        key={index}
                        form={tab.form}
                        redirect={'/thanks'}
                      />
                    )}
                  </Tab>
                ))}
              </Tabs>
            ) : (
              <>
                {activeTab.form && (
                  <FormBuilder
                    form={activeTab.form}
                    redirect={'/thanks'}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

Feedback.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    tabName: PropTypes.string,
    text: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    contacts: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      hash: PropTypes.string,
      type: PropTypes.oneOf(['phone', 'email'])
    })),
    socialTitle: PropTypes.string,
    socialValue: PropTypes.string,
    socialLinks: PropTypes.array,
    socialLinksStyle: PropTypes.oneOf(['square']),
    formBuilderUID: PropTypes.string,
    form: PropTypes.object,
  })),
  attributes: PropTypes.shape({
    class: PropTypes.string,
    id: PropTypes.string
  })
}