'use client'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import Link from '@/app/components/Ui/Link'

import styles from './UsefulLinks.module.scss'

export default function UsefulLinks({ title, items = [], attributes }) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 768,
        settings: "unslick"
      }
    ]
  }

  return (
    <div className={classNames(styles['useful-links'], `${attributes?.class || ''}`)} id={attributes?.id}>
      <div className='wr'>
        <div className={classNames(styles['useful-links_title'], 'h2')}>{title}</div>
        <Slider
          {...settings}
          className={styles['useful-links_slider']}
        >
          {items.map((item, index) => (
            <div key={index} className={styles['useful-links_slider-block']}>
              <Link
                href={item.link}
                isOpenNewTab={item.isOpenNewTab}
                className={classNames(
                  styles['useful-links_slider-link'],
                  styles[`bg-${item?.background || 'blue'}`],
                  'd-f ai-c'
                )}
              >
                <span className={classNames(styles['useful-links_slider-icon'], 'd-f ai-c jc-c icon-scale')}>
                  <img src={item.icon} alt='icon' width='30' height='30' loading='lazy' />
                </span>
                <span className={styles['useful-links_slider-text']}>
                  {item.text}
                </span>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

UsefulLinks.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    background: PropTypes.string,
    link: PropTypes.string,
    isOpenNewTab: PropTypes.any,
    icon: PropTypes.string,
    text: PropTypes.string
  })),
  attributes: PropTypes.shape({
    class: PropTypes.string,
    id: PropTypes.string
  })
}