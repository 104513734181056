'use client'
import { useId } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export default function FormInput({
  register,
  name,
  id,
  exactId,
  validation = {},
  errors = {},
  type = 'text',
  placeholder
}) {
  const uniqueId = useId()

  const getId = () => {
    return exactId || `form-${id || name}-${uniqueId}`
  }

  return (
    <div className='form_item'>
      <input
        {...register(name, { ...validation })}
        id={getId()}
        className={classNames(
          'input',
          {
            'b-error': errors?.[name]
          }
        )}
        type={type}
        placeholder={placeholder}
      />
      {placeholder && <label htmlFor={getId()} className='form_placeholder'>{placeholder}</label>}
      {errors?.[name]?.message && <div className='form_error-text'>{errors?.[name]?.message}</div>}
    </div>
  )
}

FormInput.propTypes = {
  register: PropTypes.func,
  id: PropTypes.string,
  exactId: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  validation: PropTypes.object,
  errors: PropTypes.object
}