import { gql } from 'graphql-request'
import { office } from '@/app/api/parts'

export const QueryOffices = gql`
  query Offices($locale: I18NLocaleCode, $pagination: PaginationArg) {
    offices (
      locale: $locale,
      pagination: $pagination
    ) {
      ${office}
      meta {
        pagination {
          total
          pageSize
        }
      }
    }
  }
`

export const QueryCountryRegions = gql`
  query CountryRegions($locale: I18NLocaleCode, $pagination: PaginationArg) {
    countryRegions (
      locale: $locale,
      pagination: $pagination,
      sort: "name:asc"
    ) {
      data {
        id
        attributes {
          name
        }
      }
      meta {
        pagination {
          total
          pageSize
        }
      }
    }
  }
`

export const QueryCities = gql`
  query Cities($locale: I18NLocaleCode, $pagination: PaginationArg) {
    cities (
      locale: $locale,
      pagination: $pagination,
      sort: "name:asc"
    ) {
      data {
        id
        attributes {
          name
          region {
            data {
              id
            }
          }
        }
      }
      meta {
        pagination {
          total
          pageSize
        }
      }
    }
  }
`
