'use client'
import dynamic from 'next/dynamic'
import { useTranslations } from 'next-intl'
import { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { getAllVacancies, getVacancy } from '@/app/api/vacancies/apiVacancies'
import Pagination from '@/app/components/Ui/Pagination'
import Select from '@/app/components/Ui/Select'
import Image from 'next/image'
import MegaphoneImage from '@/app/assets/images/oc-megaphone.svg'
import useEventInitializer from '@/app/hooks/useEventInitializer'

import { handleScrollToElement } from '@/app/utils/handleScrollToElement'

const VacanciesBlock = dynamic(() => import('./VacanciesBlock'))
const VacancyDetails = dynamic(() => import('./VacancyDetails'))

import styles from './Vacancies.module.scss'

const PAGINATION_PAGE_SIZE = 9

export default function Vacancies({
  title,
  image,
  lang,
  attributes,
  footerText
}) {
  const t = useTranslations('vacancies')

  const [regionOptions, setRegionOptions] = useState([])
  const [selectedRegion, setSelectedRegion] = useState()
  const [rubricOptions, setRubricOptions] = useState([])
  const [selectedRubric, setSelectedRubric] = useState()

  const [allVacancies, setAllVacancies] = useState([])
  const [vacancies, setVacancies] = useState([])

  const [isLoadingComponent, setIsLoadingComponent] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [activePages, setActivePages] = useState([])

  const [vacancyDetails, setVacancyDetails] = useState()

  const contentRef = useRef()

  const fetchAllVacancies = async ({ region, rubric }) => {
    try {
      setIsLoading(true)
      let resVacancies = await getAllVacancies(lang, region, rubric)
      // get values
      let regions = regionOptions
      if (!regions.length) {
        const getValues = await fetch(`/api/vacancies/robota/values?lang=${lang}`)
        const getValuesBody = await getValues.json()
        regions = (getValuesBody?.regions || []).filter((region) => {
          return resVacancies.vacancies.some((item) => item?.cityId === region?.value || item?.cityName === region?.label)
        })
        const rubrics = (getValuesBody?.rubrics || []).filter((rubric) => {
          return resVacancies?.rubricIds?.includes?.(rubric.value)
        })
        setRegionOptions(regions)
        setRubricOptions(rubrics)
      }
      const _allVacancies = resVacancies.vacancies.map((item) => {
        return {
          ...item,
          cityName: item?.cityName || regions?.find?.((region) => region?.value === item?.cityId)?.label
        }
      })
      setAllVacancies(_allVacancies)
      setTotal(resVacancies.total)
      setVacancies(_allVacancies.slice(0, PAGINATION_PAGE_SIZE))
      // reset pagination
      setCurrentPage(1)
      setActivePages([])
    } catch (error) {
      console.warn(error, '-> fetchAllVacancies')
    } finally {
      setIsLoadingComponent(false)
      setIsLoading(false)
    }
  }

  useEventInitializer(() => {
    fetchAllVacancies({
      region: {},
      rubric: {}
    })
  })

  const onScrollToContent = () => {
    const paginationBySections = document.getElementById('pagination-by-sections')
    const offset = paginationBySections ? paginationBySections.clientHeight : 0
    handleScrollToElement(contentRef.current, offset)
  }

  const onChangePage = async (page, isLoadMore = false) => {
    if (!page) {
      return
    }
    const data = allVacancies.slice((page - 1) * PAGINATION_PAGE_SIZE, page * PAGINATION_PAGE_SIZE)
    if (isLoadMore) {
      setVacancies([...vacancies, ...data])
      setActivePages([...activePages, currentPage])
    } else {
      setVacancies(data)
      setActivePages([])
      onScrollToContent()
    }
    setCurrentPage(page)
  }

  const onOpenVacancy = async (id, isStrapi) => {
    if (!id) {
      return
    }
    try {
      setIsLoading(true)
      const res = await getVacancy(lang, id, isStrapi)
      setVacancyDetails({
        ...(res || {}),
        cityName: res?.cityName || regionOptions.find(region => region.value === res?.cityId)?.label
      })
      onScrollToContent()
    } catch (error) {
      console.warn(error, '-> onOpenVacancy')
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeRegion = async (region) => {
    setSelectedRegion(region)
    await fetchAllVacancies({
      region,
      rubric: selectedRubric
    })
  }

  const onChangeRubric = async (rubric) => {
    setSelectedRubric(rubric)
    await fetchAllVacancies({
      rubric,
      region: selectedRegion
    })
  }

  const onHideVacancyDetails = () => {
    onScrollToContent()
    setVacancyDetails()
  }

  return (
    <div
      className={classNames(
        styles['vacancies'],
        `${attributes?.class || ''}`
      )}
      id={attributes.id}
    >
      <div className={classNames(styles['vacancies_wrapper'], 'wr d-f ai-fs fw-w jc-sb')}>
        <div className={classNames(styles['vacancies_left'], 'd-f fd-c')}>
          <h2 className={styles['vacancies_title']}>{title}</h2>
          {image && (
            <div className={classNames(styles['vacancies_image'], 'b-image-filter')}>
              <img src={image} alt={title} loading='lazy' />
            </div>
          )}
        </div>
        <div className={styles['vacancies_right']} ref={contentRef}>
          <CSSTransition
            in={isLoading || isLoadingComponent}
            timeout={400}
            unmountOnExit
            classNames="fade"
          >
            <div className={classNames(styles['vacancies_loader'], 'd-f ai-c jc-c')}>
              <span className='loader'></span>
            </div>
          </CSSTransition>
          {!vacancyDetails && (
            <>
              <div className={classNames(styles['vacancies_filter'], 'd-f ai-c fw-w')}>
                <Select
                  onChange={onChangeRegion}
                  options={regionOptions || []}
                  isClearable={true}
                  selectedOption={selectedRegion}
                  isLoading={isLoadingComponent}
                  isDisabled={isLoadingComponent}
                  placeholder={t('regionsLabel')}
                />
                <Select
                  onChange={onChangeRubric}
                  options={rubricOptions || []}
                  isClearable={true}
                  selectedOption={selectedRubric}
                  isLoading={isLoadingComponent}
                  isDisabled={isLoadingComponent}
                  placeholder={t('rubricLabel')}
                />
              </div>
              {!isLoadingComponent && (
                <>
                  {Array.isArray(vacancies) && vacancies.length ? vacancies.map((vacancy) => (
                    <VacanciesBlock
                      key={vacancy.id}
                      vacancy={vacancy}
                      onOpenVacancy={onOpenVacancy}
                    />
                  )) : <div>{t('noVacancies')}</div>}
                  {total > 1 && (
                    <Pagination
                      className={styles['vacancies_pagination']}
                      isLoading={isLoading}
                      currentPage={currentPage}
                      activePages={activePages}
                      totalCount={total}
                      pageSize={PAGINATION_PAGE_SIZE}
                      onPageChange={onChangePage}
                      onLoadMore={(page) => onChangePage(page, true)}
                    />
                  )}
                </>
              )}

              {footerText && (
                <div className={classNames(styles['vacancies_footer'], 'd-f ai-c')}>
                  <div className={classNames(styles['vacancies_footer-img'], 'fs-0 b-image-filter')}>
                    <Image
                      src={MegaphoneImage.src}
                      width={MegaphoneImage.width}
                      height={MegaphoneImage.height}
                      alt="Icon"
                    />
                  </div>
                  <div
                    className={styles['vacancies_footer-descr']}
                    dangerouslySetInnerHTML={{ __html: footerText }}
                  />
                </div>
              )}
            </>
          )}
          {vacancyDetails && (
            <VacancyDetails
              vacancy={vacancyDetails}
              onHideVacancyDetails={onHideVacancyDetails}
            />
          )}
        </div>
      </div>
    </div>
  )
}

Vacancies.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  attributes: PropTypes.shape({
    class: PropTypes.string,
    id: PropTypes.string
  }),
  footerText: PropTypes.string
}