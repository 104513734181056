'use client'
import { useTranslations } from 'next-intl'
import { useState } from 'react'
import PropTypes from 'prop-types'
import Icon from '@/app/components/Ui/Icon'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'

import spinnerIcon from '@/app/assets/images/icons/spinner.png'

import styles from './Autocomplete.module.scss'

let timeChange

export default function Autocomplete({
  options,
  selectedOption,
  placeholder,
  isLoading,
  onChange,
  onSelect
}) {
  const t = useTranslations('select')
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState('')

  const onSearch = (e) => {
    clearTimeout(timeChange)
    setValue(e.target.value)
    timeChange = setTimeout(() => {
      setIsOpen(!!e.target.value)
      onChange(e.target.value)
    }, 600)
  }

  const onSelectOption = (option) => {
    setIsOpen(false)
    setValue(option.label)
    onSelect(option)
  }

  const onClear = () => {
    setIsOpen(false)
    setValue('')
    onSelect()
  }

  return (
    <div className={classNames(styles['autocomplete'], 'autocomplete')}>
      <div className={styles['autocomplete_control']}>
        {isLoading && (
          <div className='form_input-right-icon'>
            <img
              className='d-f animation-rotation'
              src={spinnerIcon.src}
            />
          </div>
        )}
        {!isLoading && !value && (
          <Icon
            className='form_input-right-icon'
            name={'search'}
            width={24}
            height={24}
            viewBox="0 0 25 25"
          />
        )}
        {value && !isLoading && (
          <Icon
            className='form_input-right-icon b-small'
            name={'close'}
            width={16}
            height={16}
            viewBox="0 0 21 21"
            onClick={onClear}
          />
        )}
        <input
          type='text'
          className={classNames('input input--right-icon')}
          id='form-autocomplete'
          placeholder='Search'
          value={value}
          onChange={onSearch}
          onBlur={() => setIsOpen(false)}
        />
        {placeholder && <label htmlFor={`form-autocomplete`} className='form_placeholder'>{placeholder}</label>}
      </div>
      <CSSTransition
        in={isOpen && !isLoading}
        timeout={300}
        unmountOnExit
        classNames={'fade'}
      >
        <div className={styles['autocomplete_list']}>
          {Array.isArray(options) && options.length ? options.map((item) => (
            <div
              key={item.value}
              className={classNames(
                styles['autocomplete_list-option'],
                {
                  [styles['b-selected']]: item.value === selectedOption?.value
                }
              )}
              onClick={() => onSelectOption(item)}
            >
              <div className={styles['autocomplete_list-option-label']}>
                {item.label}
              </div>
              {item?.footnote && (
                <div className={styles['autocomplete_list-option-footnote']}>
                  {item.footnote}
                </div>
              )}
            </div>
          )) : (
            <div className={styles['autocomplete_no-options']}>
              {t('noOptionsMessage')}
            </div>
          )}
        </div>
      </CSSTransition>
    </div>
  )
}

Autocomplete.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  selectedOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
}