'use client'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import useModal from '@/app/hooks/useModal'
import Link from '@/app/components/Ui/Link'

import { changeImageSrc } from '@/app/utils/changeImageSrc'

import styles from './TeamModal.module.scss'

export default function TeamModal({
  onCloseModal,
  image,
  lastName,
  firstName,
  position,
  content,
  description,
  link,
  title,
  logo
}) {

  useModal()

  return (
    <div className={classNames(styles['team-modal'], 'd-f ai-c js-c')}>
      <div className={classNames(styles['team-modal_wrapper'], 'd-f fw-w ai-fs')}>
        <button
          onClick={onCloseModal}
          className={styles['team-modal_btn-close']}
          type='button'
        />
        {image && (
          <div className={styles['team-modal_image']}>
            <img src={image} />
          </div>
        )}
        <div className={styles['team-modal_content']}>
          <div className='h4'>{firstName} {lastName}</div>
          <div className={styles['team-modal_position']}>{position}</div>
          {description && (
            <div className={styles['team-modal_description']}>
              <div className='rich-text' dangerouslySetInnerHTML={{ __html: changeImageSrc(description) }} />
            </div>
          )}
          {logo && (
            <div className={styles['team-modal_logo']}>
              <img src={logo} alt='logo' />
            </div>
          )}
          {title && (
            <div className={classNames(styles['team-modal_title'], 'h4')}>{title}</div>
          )}
          {link && (
            <Link href={link} className={styles['team-modal_link']}>
              {link}
            </Link>
          )}
          {content && (
            <div className={styles['team-modal_description']}>
              <div className='rich-text' dangerouslySetInnerHTML={{ __html: changeImageSrc(content) }} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

TeamModal.propTypes = {
  onCloseModal: PropTypes.func,
  image: PropTypes.string,
  lastName: PropTypes.string,
  firstName: PropTypes.string,
  position: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  logo: PropTypes.string,
  content: PropTypes.string
}