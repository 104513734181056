'use client'
import { useTranslations } from 'next-intl'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Link from '@/app/components/Ui/Link'
import Icon from '@/app/components/Ui/Icon'

import styles from './ProductCard.module.scss'

export default function ProductCard({ title, link, mobileImage, image, background = 'blue', label }) {
  const t = useTranslations('buttons')
  return (
    <div className={classNames(styles['product-card'], styles[`bg-${background}`], 'product-card', 'd-f ai-fs jc-sb fd-c')}>
      <Link href={link} className={styles['product-card_over-link']} />
      <div className={classNames(styles['product-card_head'], 'product-card_head')}>
        <div className={classNames(styles['product-card_title'], 'product-card_title h4')}>
          {title}
        </div>
        {label && (
          <div className={classNames(styles['product-card_label'], 'product-card_label')}>
            {label}
          </div>
        )}
      </div>
      {image && (
        <div className={classNames(styles['product-card_image'], 'd-f ai-fe jc-c product-card_image')}>
          <picture className='d-f ai-fe jc-c'>
            {mobileImage && <source media="(max-width: 768px)" srcSet={mobileImage} />}
            <img src={image} width="376" height="352" />
          </picture>
        </div>
      )}
      <Link href={link} className={classNames(styles['product-card_link'], 'btn bg-white d-f ai-c jc-fe')}>
        <span className={styles['product-card_link-text']}>
          {t('details')}
        </span>
        <Icon
          className={styles['product-card_link-icon']}
          name='arrow'
          width={16}
          height={16}
          viewBox="0 0 16 16"
        />
      </Link>
    </div>
  )
}

ProductCard.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string,
  mobileImage: PropTypes.string,
  background: PropTypes.string
}