'use client'
import { useEffect } from 'react'

// ios - fixed body scrolling when modal is open
export default function useModal() {
  useEffect(() => {
    const root = document.getElementsByTagName('html')?.[0]
    const body = document.body
    if (!root || !body) {
      return
    }
    const scrollPosition = window.pageYOffset
    root.style.scrollBehavior = 'auto'
    body.style.overflow = 'hidden'
    body.style.position = 'fixed'
    body.style.top = `-${scrollPosition}px`
    body.style.width = '100%'
    return () => {
      body.style.removeProperty('overflow')
      body.style.removeProperty('position')
      body.style.removeProperty('top')
      body.style.removeProperty('width')
      window.scrollTo({ top: scrollPosition, behavior: 'auto' })
      root.style.scrollBehavior = 'smooth'
    }
  }, [])
}
