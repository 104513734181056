export const googleMapsApiKey = 'AIzaSyA_zUGku3aBe1ONyFgLbPePNCrr8NBKb0I'

export const containerStyle = {
  width: '100%',
  height: '100%'
}

export const center = {
  lat: 49.4871968,
  lng: 31.2718321
}

export const zoom = 6

export const clustererMaxZoom = 12

const baseClusterIconStyle = {
  textColor: '#151413',
  fontFamily: '"Mulish-Medium", Arial, Helvetica, sans-serif',
  fontStyle: 'normal',
  fontWeight: '500',
  textSize: 14,
  className: 'o-h',
  url: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iNDIiIHZpZXdCb3g9IjAgMCAzMiA0MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzVfMjEpIj4KPG1hc2sgaWQ9Im1hc2swXzVfMjEiIHN0eWxlPSJtYXNrLXR5cGU6bHVtaW5hbmNlIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMzIiIGhlaWdodD0iNDIiPgo8cGF0aCBkPSJNMCAwSDMyVjQySDBWMFoiIGZpbGw9IndoaXRlIi8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMF81XzIxKSI+CjxwYXRoIGQ9Ik0zMiAxNS44OTJDMzIgMjcuNDMyIDE2IDQyIDE2IDQyQzE2IDQyIDAgMjguNzU3IDAgMTUuODkyQzAgNy4xMTUgNy4xNjMgMCAxNiAwQzI0LjgzNyAwIDMyIDcuMTE1IDMyIDE1Ljg5MloiIGZpbGw9IndoaXRlIi8+CjxwYXRoIGQ9Ik0xNiAyOEMyMi42MjcgMjggMjggMjIuNjI3IDI4IDE2QzI4IDkuMzczIDIyLjYyNyA0IDE2IDRDOS4zNzMgNCA0IDkuMzczIDQgMTZDNCAyMi42MjcgOS4zNzMgMjggMTYgMjhaIiBmaWxsPSIjREREREREIi8+CjwvZz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF81XzIxIj4KPHJlY3Qgd2lkdGg9IjMyIiBoZWlnaHQ9IjQyIiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=',
}

const smallClusterIconStyle = {
  ...baseClusterIconStyle,
  width: 32,
  height: 42,
  anchorText: [-5, 0],
}

const mediumClusterIconStyle = {
  ...baseClusterIconStyle,
  width: 48,
  height: 63,
  anchorText: [-8, 0],
}

const largeClusterIconStyle = {
  ...baseClusterIconStyle,
  width: 64,
  height: 84,
  anchorText: [-10, 0],
}

export const clusterIconStyles = [
  smallClusterIconStyle,
  mediumClusterIconStyle,
  largeClusterIconStyle,
]

const markerLengthToIndex = (length) => {
  if (length > 999) {
    return 3
  } else if (length > 99) {
    return 2
  }
  return 1
}

export const markerClustererCalculator = (markers) => {
  // const _markers = markers.filter(marker => marker.visible)
  return {
    text: `${markers.length}`,
    index: markerLengthToIndex(markers.length),
    title: '',
  }
}

export const getMapBounds = (map, maps, locations, lat = 'latitude', lng = 'longitude') => {
  const bounds = new maps.LatLngBounds()

  locations.forEach((location) => {
    bounds.extend(
      new maps.LatLng(Number(location[lat]), Number(location[lng])),
    )
  })

  if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
    const extendPoint1 = new maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01)
    const extendPoint2 = new maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01)
    bounds.extend(extendPoint1)
    bounds.extend(extendPoint2)
  }

  return bounds
}

export const getMarkerIcon = (types, type) => {
  const result = types.find((option) => option.value === type)

  return result?.marker || null
}

export const onZoomIn = (map) => {
  if (!map) return

  const currentZoomLevel = map.getZoom()
  if (currentZoomLevel !== 21) map.setZoom(currentZoomLevel + 1)
}

export const onZoomOut = (map) => {
  if (!map) return

  const currentZoomLevel = map.getZoom()
  if (currentZoomLevel !== 0) map.setZoom(currentZoomLevel - 1)
}

export const selectDot = (color = 'transparent') => ({
  ':before': {
    backgroundColor: color,
  },
})