'use client'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SlickSlider from 'react-slick'

import styles from './Slider.module.scss'

export default function Slider({ items = [] }) {

  if (!Array.isArray(items)) {
    return null
  }

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    fade: true
  }

  return (
    <SlickSlider
      className={styles['slider']}
      {...settings}
    >
      {items.map((item, index) => (
        <div key={index} className={styles['slider_block']}>
          <div className={classNames(styles['slider_top'], 'd-f ai-fe')}>
            <div className={styles['slider_title']}>
              {item.title}
            </div>
            {item.label && (
              <div className={styles['slider_label']}>
                {item.label}
              </div>
            )}
          </div>
          <div className={styles['slider_text']}>
            {item.text}
          </div>
        </div>
      ))}
    </SlickSlider>
  )
}

Slider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    label: PropTypes.string,
    text: PropTypes.string
  }))
}