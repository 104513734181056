'use client'
import {useTranslations} from 'next-intl'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from '@/app/components/Ui/Icon'
import Link from '@/app/components/Ui/Link'

import styles from './LifeContacts.module.scss'

const iconByType = {
  'phone': 'phone-blue',
  'email': 'email-at',
  'location': 'location',
  'info': 'info-2',
  'schedule': 'schedule',
}

const getIconName = (type) => {
  return iconByType[type] || 'info'
}

const getHref = (type, value) => {
  if (type = 'phone') {
    return `tel:${value}`
  }

  return `mailto:${value}`
}

export default function LifeContacts({
  title,
  groups,
  attributes,
}) {
  const t = useTranslations('map')

  return (
    <div
      className={classNames(
        styles['contacts'],
        `${attributes?.class || ''}`
      )}
      id={attributes.id}
    >
      <div className='wr'>
        <h2 className={styles['contacts_title']}>{title}</h2>

        {Array.isArray(groups) && groups.length !== 0 && (
          <div
            className={classNames(
              styles['contacts_content'],
              'd-f fw-w',
            )}
          >
            {groups.map((group, gIndex) => (
              <div
                key={gIndex}
                className={classNames(
                  styles['contacts_group'],
                )}
              >
                {group.title && (
                  <div
                    className={classNames(
                      styles['contacts_group-title'],
                      'h5',
                    )}
                  >{group.title}</div>
                )}

                {Array.isArray(group.items) && group.items !== 0 && (
                  <div
                    className={classNames(
                      styles['contacts_items'],
                    )}
                  >
                    {group.items.map((item, index) => (
                      <div
                        key={`${gIndex}-${index}`}
                        className={classNames(
                          styles['contacts_item'],
                          styles[`is-${item.type}`]
                        )}
                      >
                        {item.type === 'phone' || item.type === 'email' ? (
                          <a
                            className={classNames(styles['contacts_item-wr'], 'd-f')}
                            href={getHref(item.type, item.value)}
                          >
                            <Icon
                              className={styles['contacts_item-icon']}
                              name={getIconName(item.type)}
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                            />
                            <span className={styles['contacts_item-right']}>
                              <span
                                className={classNames(styles['contacts_item-value'], 'text-2 ws-pl')}
                                dangerouslySetInnerHTML={{ __html: item.value }}
                              />
                            </span>
                          </a>
                        ) : (
                          <span
                            className={classNames(styles['contacts_item-wr'], 'd-f')}
                          >
                            <Icon
                              className={styles['contacts_item-icon']}
                              name={getIconName(item.type)}
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                            />
                            <span className={styles['contacts_item-right']}>
                              <span
                                className={classNames(styles['contacts_item-value'], 'text-2 ws-pl')}
                                dangerouslySetInnerHTML={{ __html: item.value }}
                              />
                              {item.type === 'location' && item.locationLink && (
                                <span className={styles['contacts_item-map']}>
                                  <Link
                                    className='link-arrow'
                                    href={item.locationLink}
                                    isOpenNewTab={item.isOpenNewTab}
                                  >
                                    {t('onMap')}
                                    <Icon
                                      name='arrow'
                                      width={16}
                                      height={16}
                                      viewBox="0 0 16 16"
                                    />
                                  </Link>
                                </span>
                              )}
                            </span>
                          </span>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

LifeContacts.propTypes = {
  title: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      type: PropTypes.oneOf([
        'phone',
        'email',
        'location',
        'info',
        'schedule'
      ]),
      locationLink: PropTypes.string,
      isOpenNewTab: PropTypes.any,
    })),
  })),
  attributes: PropTypes.shape({
    class: PropTypes.string,
    id: PropTypes.string
  }),
}
