import { WEBSITE_URL } from '@/app/constants/constants'
import { formatPhoneNumber } from "@/app/utils/formHelpers"
import { getActiveLang } from '@/app/utils/getActiveLang'

const getValueByType = (value, type) => {
  if (type === 'phone') {
    return formatPhoneNumber(value)
  }

  return value
}

const getFormBuilderI18n = (data, locale) => {
  const { localizations } = data?.attributes

  if (locale && locale !== 'ua') {
    return localizations?.data?.find((i) => i?.attributes?.locale === locale)
  }

  return data
}

export const fetchFormBuilderData = async (name, locale) => {
  if (!name) return undefined
  try {
    const isServerSide = typeof window === 'undefined'
    const response = await fetch(`${isServerSide ? WEBSITE_URL : ''}/api/form-builder?name=${name}&locale=${getActiveLang(locale)}`)
    const res = await response.json()
    if (!response.ok) {
      throw new Error(`${res.error.status} (${res.error.message})`)
    }

    return {
      ...getFormBuilderI18n(res.data, locale),
      id: res.data.id
    }
  } catch (error) {
    console.error(error, '-> fetchFormBuilderData')
  }
}

export const submitFormBuilder = async (id, data, types) => {
  try {
    const formData = new FormData()
    for (const key in data) {
      if (data.hasOwnProperty(key) && typeof data[key] !== 'undefined' && data[key] !== '' && !key.startsWith('collapsible-')) {
        if (types[key] === 'file' && typeof data[key] === 'object' && data[key].length !== 0) {
          Array.from(data[key]).forEach(i => {
            formData.append(key, i)
          })
        } else {
          formData.append(key, getValueByType(data[key], types[key]))
        }
      }
    }
    formData.append('id', id)
    const response = await fetch(`/api/form-builder`, {
      method: 'POST',
      body: formData
    })
    const res = await response.json()
    if (!response.ok) {
      throw new Error(`${res.error.status} (${res.error.message})`)
    }
    return res
  } catch (error) {
    console.error(error, '-> fetchFormBuilderData')
    throw new Error(`${res.error.status} (${res.error.message})`)
    
  }
}

export const submitFormSubscribe = async (data) => {
  try {
    const response = await fetch(`/api/form-builder/subscribe`, {
      method: 'POST',
      body: JSON.stringify(data)
    })
    const res = await response.json()
    if (!response.ok) {
      throw new Error(`${res.error.status} (${res.error.message})`)
    }
    return res
  } catch (error) {
    console.error(error, '-> submitFormSubscribe')
  }
}
