'use client'
import { useTranslations } from 'next-intl'
import { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { clientFetchRequest } from '@/app/api/apiClient'
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  InfoWindowF,
  MarkerClustererF,
} from '@react-google-maps/api'
import {
  googleMapsApiKey,
  containerStyle,
  center,
  zoom,
  clusterIconStyles,
  markerClustererCalculator,
  clustererMaxZoom,
  getMapBounds,
  getMarkerIcon,
  onZoomIn,
  onZoomOut,
  selectDot,
} from '@/app/utils/mapHelpers'
import { createFilter } from 'react-select'
import Icon from '@/app/components/Ui/Icon'
import { CSSTransition } from 'react-transition-group'
import Autocomplete from '@/app/components/Ui/Autocomplete'
import Select from '@/app/components/Ui/Select'
import useEventInitializer from '@/app/hooks/useEventInitializer'

import styles from './MapWithClinics.module.scss'

const PAGE_SIZE = 20

let timeFilter
let timeMap
let timeCity

import pin1 from '@/app/assets/images/pins/1.svg'
import pin2 from '@/app/assets/images/pins/2.svg'
import pin3 from '@/app/assets/images/pins/3.svg'
import pin4 from '@/app/assets/images/pins/4.svg'
import pin5 from '@/app/assets/images/pins/5.svg'
import pin6 from '@/app/assets/images/pins/6.svg'
import pin7 from '@/app/assets/images/pins/7.svg'
import pin9 from '@/app/assets/images/pins/9.svg'

const CustomMarkerClusterer = ({ setSelectedPlace, markers, types_options, selectedPlace }) => useMemo(() => (
  <MarkerClustererF
    options={{
      averageCenter: true,
      ignoreHidden: true,
      calculator: markerClustererCalculator,
      styles: clusterIconStyles,
      maxZoom: clustererMaxZoom,
    }}
    onUnmount={(clusterer) => clusterer.clearMarkers()}
  >
    {(clusterer) => {
      clusterer.repaint()
      return (
        markers.map((place) => {
          return (
            <MarkerF
              key={`${place.Latidute}-${place.Longitude}-${place.StoreName}-${place.StoreId}`}
              visible={place.visible}
              onClick={() => {
                place === selectedPlace
                  ? setSelectedPlace(undefined)
                  : setSelectedPlace(place)
              }}
              position={{
                lat: Number(place.Latidute),
                lng: Number(place.Longitude)
              }}
              icon={getMarkerIcon(types_options, place.StoreTypeId)}
              clusterer={clusterer}
            ></MarkerF>
          )
        })
      )
    }}
  </MarkerClustererF>
), [markers, types_options, selectedPlace, setSelectedPlace])

export default function MapWithClinics({
  title,
  attributes,
  downloadTitle,
  downloadFile,
  // downloadFileExt
}) {
  const t = useTranslations('map')
  const legendsRef = useRef(null)
  const types_options = useMemo(() => [
    {
      label: t('medicalInstitutions'),
      value: 2,
      color: '#499BD5',
      marker: pin2.src
    },
    {
      label: t('pharmacies'),
      value: 1,
      color: '#4CA465',
      marker: pin1.src
    },
    {
      label: t('diagnosticCenters'),
      value: 7,
      color: '#5D49D5',
      marker: pin7.src
    },
    {
      label: t('laboratories'),
      value: 4,
      color: '#D549C7',
      marker: pin4.src
    },
    {
      label: t('healthFacilities'),
      value: 3,
      color: '#A8D549',
      marker: pin3.src
    },
    {
      label: t('optics'),
      value: 6,
      color: '#A3BCCD',
      marker: pin6.src
    },
    {
      label: t('ophthalmology'),
      value: 9,
      color: '#D58C49',
      marker: pin9.src
    },
    {
      label: t('dentistry'),
      value: 5,
      color: '#EFE483',
      marker: pin5.src
    }
  ], [t])

  const [map, setMap] = useState(null)
  const [legendsIsOpen, setLegendsIsOpen] = useState(false)
  const [selectedPlace, setSelectedPlace] = useState(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [markers, setMarkers] = useState([])

  const [page, setPage] = useState(1)
  const [hasPage, setHasPage] = useState(false)
  const [allCity, setAllCity] = useState([])
  const [cityOptions, setCityOptions] = useState([])

  const [selectedCity, setSelectedCity] = useState(null)
  const [selectedType, setSelectedType] = useState(null)

  const [autocompleteOptions, setAutocompleteOptions] = useState([])
  const [selectedAutocomplete, setSelectedAutocomplete] = useState()

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey
  })

  const centerMarkers = (map, localMarkers) => {
    const bounds = getMapBounds(map, window.google.maps, localMarkers, 'Latidute', 'Longitude')
    map.fitBounds(bounds)
  }

  const onLoad = useCallback(function callback(map) {
    setMap(map)
  }, [setMap])

  const onUnmount = useCallback(function callback() {
    setMap(null)
  }, [setMap])

  const fetchData = async () => {
    if (isLoading) return

    setIsLoading(true)

    try {
      const response = await clientFetchRequest({
        method: 'POST',
        url: 'ingo-api/insurance/universal',
        body: {
          'Header': {
            'AppId': '20',
            'ServiceName': 'Api.Web.Medicine.Lpu.Store.List'
          }
        }
      })
      const data = response?.data
      const stores = (data?.LpuStoreList || [])
        .filter(i => !!i.Longitude && !!i.Latidute)
        .map((i) => ({
          ...i,
          Latidute: i.Latidute.replace(',', '.'),
          Longitude: i.Longitude.replace(',', '.'),
          visible: true
        }))
      const cities = []

      stores.forEach((store) => {
        if (!cities.some((city) => city.value === `${store.OblastId}-${store.CityId}`)) {
          cities.push({
            label: `${store.CityName}, ${store.OblastName}`,
            value: `${store.OblastId}-${store.CityId}`,
            storeName: store?.StoreTypeName,
            address: `${store?.CityName}, ${store?.Address}`
          })
        }
      })

      setMarkers(stores)
      setAllCity(cities)
      setHasPage(cities.length > PAGE_SIZE)
      setCityOptions(cities.slice(0, PAGE_SIZE))
    } catch (error) {
      console.warn(error, '-> MapWithClinics fetchData')
    } finally {
      setIsLoading(false)
    }
  }

  useEventInitializer(() => {
    fetchData()
  })

  useEffect(() => {
    setSelectedPlace(undefined)
    if (!selectedCity && !selectedType && !selectedAutocomplete) {
      return setMarkers(prev => prev.map((x) => ({
        ...x,
        visible: true
      })))

    }
    clearTimeout(timeFilter)
    timeFilter = setTimeout(() => {
      const filter = markers.map((store) => {
        const filteredByCity = selectedCity ? selectedCity.value === `${store.OblastId}-${store.CityId}` : true
        const filteredByType = selectedType ? selectedType.value === store.StoreTypeId : true
        const filteredByAutocomplete = selectedAutocomplete ? selectedAutocomplete.value === `${store.OblastId}-${store.CityId}-${store.StoreId}` : true

        return {
          ...store,
          visible: filteredByCity && filteredByType && filteredByAutocomplete
        }
      })
      setMarkers(filter)
    }, 200)
    return () => {
      clearTimeout(timeFilter)
    }
  }, [selectedCity, selectedType, selectedAutocomplete])

  useEffect(() => {
    if (!markers?.length || !map || !isLoaded) return
    clearTimeout(timeMap)
    const _markers = markers.filter(i => i.visible)
    map.setZoom(1)
    timeMap = setTimeout(() => {
      centerMarkers(map, _markers)
    }, 0)
    return () => {
      clearTimeout(timeMap)
    }
  }, [markers, isLoaded, map])

  useEffect(() => {
    const handleOutSideClick = (event) => {
      if (!legendsRef.current?.contains(event.target)) {
        setLegendsIsOpen(false)
      }
    }

    window.addEventListener("mousedown", handleOutSideClick)

    return () => {
      window.removeEventListener("mousedown", handleOutSideClick)
    }
  }, [legendsRef])

  const filterCityOptions = (searchValue) => {
    clearTimeout(timeCity)
    timeCity = setTimeout(() => {
      const cities = Array.isArray(allCity) ? allCity.filter((item) => {
        const value = (searchValue || '').toLowerCase()
        const filteredByAddress = ((item?.address || '').toLowerCase()).includes(value)
        const filteredByLabel = ((item?.label || '').toLowerCase()).includes(value)
        const filteredByStoreName = ((item?.storeName || '').toLowerCase()).includes(value)
        return filteredByAddress || filteredByLabel || filteredByStoreName
      }) : []
      setCityOptions(cities.slice(0, PAGE_SIZE))
      setPage(1)
    }, 600)
  }

  const onLoadMoreCity = () => {
    if (!hasPage) {
      return
    }
    const cities = allCity.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)
    const arrCities = [...cityOptions, ...cities]
    setPage(page + 1)
    setCityOptions([...cityOptions, ...cities])
    setHasPage(arrCities.length < allCity.length)
  }

  const onAutocomplete = (searchValue) => {
    if (!searchValue) {
      setAutocompleteOptions([])
    }
    const cities = []
    const value = (searchValue || '').toLowerCase()
    const findMarkers = Array.isArray(markers) ? markers.filter((item) => {
      const filteredByAddress = ((`${item?.CityName}, ${item?.Address}`).toLowerCase()).includes(value)
      const filteredByLabel = ((`${item.CityName}, ${item.OblastName}`).toLowerCase()).includes(value)
      const filteredByStoreName = ((item?.StoreTypeName || '').toLowerCase()).includes(value)
      const filteredByName = ((item?.StoreName || '').toLowerCase()).includes(value)
      const filteredByLpuName = ((item?.LpuName || '').toLowerCase()).includes(value)
      return filteredByAddress || filteredByLabel || filteredByStoreName || filteredByName || filteredByLpuName
    }) : []
    findMarkers.forEach((store) => {
      const value = `${store.OblastId}-${store.CityId}-${store.StoreId}`
      if (!cities.some((city) => city.value === value)) {
        cities.push({
          value,
          label: store.StoreName,
          footnote: `${store?.CityName}, ${store?.Address}`
        })
      }
    })
    setAutocompleteOptions(cities)
  }

  const onSelectAutocomplete = (item) => {
    setSelectedCity(null)
    setSelectedType(null)
    setSelectedAutocomplete(item)
  }

  return (
    <div
      className={classNames(
        styles['map-with-clinics'],
        `${attributes?.class || ''}`
      )}
      id={attributes.id}
    >
      <div
        className={classNames(
          styles['map-with-clinics_wrapper'],
          'wr'
        )}
      >
        <h2 className={styles['map-with-clinics_title']}>{title}</h2>

        <div
          className={classNames(
            styles['map-with-clinics_filter'],
            'd-f'
          )}
        >
          <Select
            onChange={(value) => {
              setSelectedCity(value)
              setSelectedAutocomplete()
            }}
            options={cityOptions}
            selectedOption={selectedCity}
            isClearable={true}
            filterOption={createFilter({ ignoreAccents: false })}
            isLoading={isLoading}
            onInputChange={filterCityOptions}
            onMenuScrollToBottom={onLoadMoreCity}
            placeholder={t('city')}
          />
          <Select
            onChange={(value) => {
              setSelectedType(value)
              setSelectedAutocomplete()
            }}
            options={types_options}
            selectedOption={selectedType}
            isClearable={true}
            placeholder={t('type')}
            className={'select-with-dot'}
            styles={{
              option: (styles, { data }) => ({ ...styles, ...selectDot(data.color) }),
              singleValue: (styles, { data }) => ({ ...styles, ...selectDot(data.color) })
            }}
          />
          <Autocomplete
            onChange={onAutocomplete}
            options={autocompleteOptions}
            placeholder={t('search')}
            isLoading={isLoading}
            selectedOption={selectedAutocomplete}
            onSelect={onSelectAutocomplete}
          />
        </div>

        <div className={styles['map-with-clinics_map']}>
          {isLoaded && (
            <>
              <GoogleMap
                options={{
                  disableDefaultUI: true
                }}
                mapContainerStyle={containerStyle}
                center={center}
                zoom={zoom}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
                {Array.isArray(markers) && markers.length !== 0 && (
                  <CustomMarkerClusterer
                    markers={markers}
                    types_options={types_options}
                    setSelectedPlace={setSelectedPlace}
                    selectedPlace={selectedPlace}
                  />
                )}

                {selectedPlace && (
                  <InfoWindowF
                    position={{
                      lat: Number(selectedPlace.Latidute),
                      lng: Number(selectedPlace.Longitude)
                    }}
                    zIndex={1}
                    options={{
                      pixelOffset: {
                        width: 0,
                        height: -40,
                      }
                    }}
                    onCloseClick={() => setSelectedPlace(undefined)}
                  >
                    <div className={styles['map-with-clinics_infowindow']}>
                      <button
                        className={classNames(
                          styles['map-with-clinics_infowindow-close'],
                          'd-f ai-c'
                        )}
                        type="button"
                        onClick={() => setSelectedPlace(undefined)}
                      >
                        <Icon
                          name={'cross'}
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                        />
                      </button>

                      <div
                        className={styles['map-with-clinics_infowindow-title']}
                      >{selectedPlace.StoreName}</div>
                      <div
                        className={styles['map-with-clinics_infowindow-subtitle']}
                        style={{ color: types_options.find((store) => store.value === selectedPlace.StoreTypeId).color || null }}
                      >{selectedPlace.StoreTypeName}</div>
                      <div
                        className={styles['map-with-clinics_infowindow-subtitle']}
                      >{selectedPlace.LpuName}</div>
                      <div
                        className={styles['map-with-clinics_infowindow-address']}
                      >{selectedPlace.CityName}, {selectedPlace.OblastName}, {selectedPlace.Address}</div>
                    </div>
                  </InfoWindowF>
                )}
              </GoogleMap>

              <div
                ref={legendsRef}
                className={styles['map-with-clinics_legends']}
              >
                <button
                  className={classNames(
                    legendsIsOpen && styles['is-active'],
                    'd-f ai-c jc-c'
                  )}
                  type='button'
                  onClick={() => setLegendsIsOpen(!legendsIsOpen)}
                >
                  <Icon
                    name={'legends'}
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                  />
                </button>

                <CSSTransition
                  in={legendsIsOpen}
                  timeout={300}
                  unmountOnExit
                  classNames="fade"
                >
                  <div
                    className={styles['map-with-clinics_legends-list']}
                  >
                    <div
                      className={styles['map-with-clinics_legends-list-content']}
                    >
                      {types_options.map((item) => (
                        <div
                          key={item.value}
                          className={classNames(
                            styles['map-with-clinics_legends-item'],
                            'd-f ai-c'
                          )}
                        >
                          <span className='map-circle' style={{ backgroundColor: item.color }}></span>
                          {item.label}
                        </div>
                      ))}
                    </div>
                  </div>
                </CSSTransition>
              </div>

              <div className={styles['map-with-clinics_zoom']}>
                <button
                  className='d-f ai-c jc-c'
                  type='button'
                  onClick={() => onZoomIn(map)}
                >
                  <Icon
                    name={'zoom-plus'}
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                  />
                </button>
                <button
                  className='d-f ai-c jc-c'
                  type='button'
                  onClick={() => onZoomOut(map)}
                >
                  <Icon
                    name={'zoom-minus'}
                    width={20}
                    height={20}
                    viewBox="0 0 20 20"
                  />
                </button>
              </div>
            </>
          )}
        </div>

        {downloadFile && downloadTitle && (
          <div className={styles['map-with-clinics_file']}>
            <a
              className='d-if'
              href={downloadFile}
              target='_blank'
            >
              <Icon
                name={'download'}
                width={24}
                height={24}
                viewBox="0 0 24 24"
              />
              <span>{downloadTitle}</span>
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

MapWithClinics.propTypes = {
  title: PropTypes.string,
  attributes: PropTypes.shape({
    class: PropTypes.string,
    id: PropTypes.string
  }),
  downloadTitle: PropTypes.string,
  downloadFile: PropTypes.string,
  // downloadFileExt: PropTypes.string,
}