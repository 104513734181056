'use client'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import SlickSlider from 'react-slick'
import Icon from '@/app/components/Ui/Icon'

import styles from './TeamsSlider.module.scss'

export default function TeamsSlider({
  items,
  attributes
}) {

  if (!Array.isArray(items)) {
    return null
  }

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    adaptiveHeight: true,
    fade: true
  }

  return (
    <div className={classNames(styles['teams-slider'], `${attributes?.class || ''}`)} id={attributes?.id}>
      <div className='wr'>
        <SlickSlider {...settings} className={classNames(styles['teams-slider_wrapper'])}>
          {items.map((item, index) => (
            <div key={index} className={classNames(styles['teams-slider_block'], 'd-f ai-s jc-sb fw-w')}>
              <div className={styles['teams-slider_image']}>
                <img src={item.image} alt={item?.person?.name || 'avatar'} loading='lazy' />
              </div>
              <div className={classNames(styles['teams-slider_content'], 'd-f ai-fs jc-fs fd-c')}>
                <div className={styles['teams-slider_quotation-icon']}>
                  <Icon
                    name={'quotation-2'}
                    width={60}
                    height={53}
                    viewBox="0 0 60 53"
                  />
                </div>
                <div className={classNames(styles['teams-slider_quotation'], 'h4')}>
                  {item.quotation}
                </div>
                {item.text && (
                  <div
                    className={classNames(styles['teams-slider_text'], 'rich-text')}
                    dangerouslySetInnerHTML={{__html: item.text}}
                  />
                )}
                <div className={styles['teams-slider_bottom']}>
                  <div className={classNames(styles['teams-slider_user-name'], 'h4')}>
                    {item?.person?.name}
                  </div>
                  <div className={styles['teams-slider_user-position']}>
                    {item?.person?.position}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </SlickSlider>
      </div>
    </div>
  )
}

TeamsSlider.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.string,
    quotation: PropTypes.string,
    text: PropTypes.string,
    person: PropTypes.shape({
      name: PropTypes.string,
      position: PropTypes.string
    })
  })),
  attributes: PropTypes.shape({
    class: PropTypes.string,
    id: PropTypes.string
  })
}