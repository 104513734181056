'use client'
import PropTypes from 'prop-types'

export default function Tab({ children }) {
  return children
}

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  tabKey: PropTypes.any.isRequired
}