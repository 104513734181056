"use client"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Tooltip } from "react-tooltip"
import Icon from "@/app/components/Ui/Icon"

import styles from "./PackagesInfo.module.scss"

export default function PackagesInfo({ data }) {
  return (
    <div className={styles["packages-info"]}>
      {data.map((item, index) => (
        <div
          key={index}
          className={classNames(
            styles["packages-info-item"],
            "js-packages-item d-f fd-c",
            {
              'jc-c': index !== 0
            }
          )}
        >
          {item.title && (
            <div className={styles["packages-info-title"]}>
              {item.title}
              {item.tooltipText && (
                <>
                  {" "}
                  <Tooltip
                    className={styles['packages-info-tooltip']}
                    id={`packages-info-${index}`}
                    place="bottom-start"
                    content={item.tooltipText}
                    noArrow
                    disableStyleInjection
                  />
                  <Icon
                    data-tooltip-id={`packages-info-${index}`}
                    className={styles["packages-info-icon"]}
                    name={"info-2"}
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                  />
                </>
              )}
            </div>
          )}
          {item.text && (
            <div className={styles["packages-info-text"]}>
              {item.text}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

PackagesInfo.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    tooltipText: PropTypes.string,
    text: PropTypes.string
  }))
}
