export const mapOffices = (office) => {
  const type = office?.attributes?.type || 'ingo'
  return {
    id: office.id,
    type: type,
    officeType: type === 'agent' ? 'agent' : 'office',
    address: office?.attributes?.address,
    phones: office?.attributes?.phone?.split(',')?.map((i) => i?.trim()),
    emails: office?.attributes?.email?.split(',')?.map((i) => i?.trim()),
    timeWork: office?.attributes?.timeWork?.split(',')?.map((i) => i?.trim())?.join('\n'),
    latitude: office?.attributes?.latitude,
    longitude: office?.attributes?.longitude,
    city: {
      id: office?.attributes?.city?.data?.id,
      name: office?.attributes?.city?.data?.attributes?.name,
    },
    region: {
      id: office?.attributes?.city?.data?.attributes?.region?.data?.id,
      name: office?.attributes?.city?.data?.attributes?.region?.data?.attributes?.name,
    },
    title: (office?.attributes?.title || '').trim() || `${type === 'general' ? 'Головний офіс, ' : ''}${office?.attributes?.city?.data?.attributes?.name}`
  }
}

export const mapOfficesData = (data) => {
  const res = data?.offices

  return {
    offices: Array.isArray(res?.data) ? res.data.map(mapOffices).sort((a, b) => a.title.localeCompare(b.title)) : [],
    pagination: {
      total: res?.meta?.pagination?.total,
      pageSize: res?.meta?.pagination?.pageSize
    }
  }
}

export const mapCountryRegionsData = (data) => {
  const res = data?.countryRegions

  return {
    regions: Array.isArray(res?.data) ? res.data.map((region) => ({
      value: region.id,
      label: region?.attributes?.name || region.id,
    })) : [],
    pagination: {
      total: res?.meta?.pagination?.total,
      pageSize: res?.meta?.pagination?.pageSize
    }
  }
}

export const mapCitiesData = (data) => {
  const res = data?.cities

  return {
    cities: Array.isArray(res?.data) ? res.data.map((city) => ({
      value: city.id,
      label: city?.attributes?.name || city.id,
      region: {
        id: city?.attributes?.region?.data?.id
      }
    })) : [],
    pagination: {
      total: res?.meta?.pagination?.total,
      pageSize: res?.meta?.pagination?.pageSize
    }
  }
}
