'use client'
import { useId } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export default function FormTextarea({
  register,
  name,
  exactId,
  validation = {},
  errors = {},
  placeholder
}) {
  const uniqueId = useId()

  const getId = () => {
    return exactId || `form-${name}-${uniqueId}`
  }

  return (
    <div
      className={classNames(
        'form_item textarea',
        {
          'b-error': errors?.[name]
        }
      )}
    >
      <textarea
        {...register(name, { ...validation })}
        id={getId()}
        className={'textarea_input'}
        placeholder={placeholder}
      />
      {placeholder && <label htmlFor={getId()} className='form_placeholder'>{placeholder}</label>}
      {errors?.[name]?.message && <div className='form_error-text'>{errors?.[name]?.message}</div>}
    </div>
  )
}

FormTextarea.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  exactId: PropTypes.string,
  placeholder: PropTypes.string,
  validation: PropTypes.object,
  errors: PropTypes.object
}