'use client'
import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '@/app/components/Ui/Icon'
import Link from '@/app/components/Ui/Link'

import styles from './InsuranceProducts.module.scss'

export default function InsuranceProducts({
  title,
  text,
  linkText,
  link,
  items = [],
  attributes
}) {
  const [activeItem, setActiveItem] = useState(0)
  const [innerWith, setInnerWith] = useState(0)
  const itemsRef = useRef([])

  const initInnerWith = () => {
    if (innerWith || window.innerWidth <= 768) {
      return
    }
    setInnerWith(itemsRef.current?.[activeItem]?.clientWidth)
  }

  const updateInnerWith = () => {
    if (window.innerWidth <= 768) {
      return
    }
    setInnerWith(itemsRef.current[activeItem].parentElement.clientWidth)
  }

  useEffect(() => {
    initInnerWith()
  }, [])

  useEffect(() => {
    window.addEventListener('resize', updateInnerWith)
    return () => {
      window.removeEventListener('resize', updateInnerWith)
    }
  }, [activeItem])

  const onActiveItem = (index) => {
    setActiveItem(index)
  }


  return (
    <div
      className={classNames(
        styles['insurance-products'],
        `${attributes?.class || ''}`
      )}
      id={attributes.id}
    >
      <div className={classNames(styles['insurance-products_wrapper'], 'wr d-f ai-fs fw-w jc-sb')}>
        <div className={styles['insurance-products_left']}>
          <h1>{title}</h1>
          <div className={styles['insurance-products_text']}>
            {text}
          </div>
          {link && linkText && (
            <Link href={link} className={classNames(styles['insurance-products_btn-link'], 'btn b-with-icon')}>
              {linkText}
              <Icon
                name='arrow'
                width={16}
                height={16}
                viewBox="0 0 16 16"
              />
            </Link>
          )}
        </div>
        <div className={classNames(styles['insurance-products_right'], 'd-f ai-s')}>
          {Array.isArray(items) && items.length && items.map((item, index) => {
            const isActive = activeItem === index
            let width = innerWith ? `${innerWith}px` : '233%'
            if (isActive && !innerWith) {
              width = '100%'
            }
            return (
              <div
                key={index}
                className={classNames(
                  styles['insurance-products_block'],
                  styles[`bg-${item?.background || 'blue'}`],
                  {
                    [styles['b-active']]: isActive,
                    [styles['is-empty']]: !item?.links?.length
                  }
                )}
                onMouseEnter={() => onActiveItem(index)}
                onClick={() => onActiveItem(index)}
              >
                <div className={styles['insurance-products_content']}>
                  <div
                    className={classNames(styles['insurance-products_inner'], 'd-f ai-fs jc-fs fd-c')}
                    ref={el => itemsRef.current[index] = el}
                    style={{ width }}
                  >
                    {item.link && !item?.links?.length && (
                      <Link
                        href={item.link}
                        className={styles['insurance-products_over-link']}
                      />
                    )}
                    <div
                      className={styles['insurance-products_name']}
                      dangerouslySetInnerHTML={{ __html: item?.name || '' }}
                    />
                    {item?.links?.length > 0 && (
                      <div className={styles['insurance-products_links']}>
                        {item?.links.map((link, linkIndex) => (
                          <Link
                            key={linkIndex}
                            href={link.link}
                            isOpenNewTab={link.isOpenNewTab}
                            className={styles['insurance-products_link']}
                          >
                            <span className={styles['insurance-products_link-text']}>
                              {link.name}
                            </span>
                            {link.label && (
                              <>
                                &nbsp;
                                <span className={styles['insurance-products_link-label']}>
                                  {link.label}
                                </span>
                              </>
                            )
                            }
                          </Link>
                        ))}
                        {item.link && item.linkText && (
                          <Link
                            href={item.link}
                            isOpenNewTab={item.isOpenNewTab}
                            className={classNames(styles['insurance-products_link-arrow'], 'link-arrow')}
                          >
                            {item.linkText}
                            <Icon
                              name='arrow'
                              width={13}
                              height={14}
                              viewBox="0 0 16 16"
                            />
                          </Link>
                        )}
                      </div>
                    )}
                    {item.link && !item?.links?.length && (
                      <Link
                        href={item.link}
                        className={classNames(styles['insurance-products_btn'], 'btn bg-white b-only-icon')}
                      >
                        <Icon
                          name='arrow'
                          width={24}
                          height={24}
                          viewBox="0 0 16 16"
                        />
                      </Link>
                    )}
                    {item.image && (
                      <div className={classNames(styles['insurance-products_image'], 'd-f ai-fe jc-c')}>
                        <picture className='d-f ai-fe jc-c'>
                          {item.mobileImage && <source media="(max-width: 768px)" srcSet={item.mobileImage} />}
                          <img src={item.image} loading={index ? 'lazy' : 'eager'} width='406' height='352' />
                        </picture>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        {link && linkText && (
          <Link href={link} className={classNames(styles['insurance-products_btn-link'], 'btn b-with-icon')}>
            {linkText}
            <Icon
              name='arrow'
              width={16}
              height={16}
              viewBox="0 0 16 16"
            />
          </Link>
        )}
      </div>
    </div>
  )
}

InsuranceProducts.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  linkText: PropTypes.string,
  link: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    background: PropTypes.string,
    image: PropTypes.string,
    mobileImage: PropTypes.string,
    name: PropTypes.string,
    linkText: PropTypes.string,
    link: PropTypes.string,
    isOpenNewTab: PropTypes.any,
    links: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
      isOpenNewTab: PropTypes.any,
      label: PropTypes.string
    }))
  })),
  attributes: PropTypes.shape({
    class: PropTypes.string,
    id: PropTypes.string
  })
}