'use client'
import { useId } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export default function FormCheckbox({
  register,
  name,
  exactId,
  validation = {},
  errors = {},
  text
}) {
  const uniqueId = useId()

  const getId = () => {
    return exactId || `form-${name}-${uniqueId}`
  }

  return (
    <label className='form_checkbox' htmlFor={getId()}>
      <input
        {...register(name, { ...validation })}
        id={getId()}
        className={classNames(
          'form_checkbox-control',
          {
            'b-error': errors?.[name]
          }
        )}
        type='checkbox'
      />
      {text && (
        <span className='form_checkbox-text' dangerouslySetInnerHTML={{ __html: text }} />
      )}
    </label>
  )
}

FormCheckbox.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
  exactId: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  validation: PropTypes.object,
  errors: PropTypes.object
}