import { gql } from 'graphql-request'

export const QueryVacancies = gql`
  query Vacancies(
    $filters: VacancyFiltersInput,
    $pagination: PaginationArg,
    $locale: I18NLocaleCode
  ) {
    vacancies(
      filters: $filters,
      pagination: $pagination,
      locale: $locale
    ) {
      data {
        id
        attributes {
          name
          rubricId
          natureOfWork
          updatedAt
          city {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
      meta {
        pagination {
          total
          pageSize
        }
      }
    }
  }
`

export const QueryVacancy = gql`
  query Vacancy(
    $id: ID,
    $locale: I18NLocaleCode
  ) {
    vacancy(
      id: $id,
      locale: $locale
    ) {
      data {
        id
        attributes {
          name
          description
          rubricId
          scheduleId
          natureOfWork
          ContactPhone
          ContactPerson
          AdditionalContact
          updatedAt
          city {
            data {
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`