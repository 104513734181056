'use client'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from '@/app/components/Ui/Icon'

import styles from './DocumentLink.module.scss'

export default function DocumentLink({
  name,
  link,
  attrId,
  ext
}) {
  return (
    <a
      href={link}
      target='_blank'
      className={classNames(styles['document-link'], 'document-link')}
      id={attrId}
    >
      <Icon
        className={classNames(styles['document-link_icon'], 'document-link_icon')}
        name={'document'}
        width={49}
        height={48}
        viewBox="0 0 49 48"
      />
      <span className={classNames(styles['document-link_name'], 'document-link_name')}>{name}</span>
      {ext && (
        <span className={classNames(styles['document-link_ext'], 'document-link_ext')}>{ext}</span>
      )}
    </a>
  )
}

DocumentLink.propTypes = {
  name: PropTypes.string,
  attrId: PropTypes.string,
  link: PropTypes.string,
  ext: PropTypes.string
}