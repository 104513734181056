'use client'
import {useTranslations} from 'next-intl'
import { useState } from 'react'
import classNames from 'classnames'
import ReactSelect from 'react-select'
import PropTypes from 'prop-types'
import { useController } from 'react-hook-form'

export default function FormSelect({
  name,
  exactId,
  options = [],
  isShowLabel = false,
  validation = {},
  control,
  errors = {},
  placeholder,
}) {
  const t = useTranslations('select')
  const {
    field
  } = useController({
    name,
    control,
    rules: { ...validation }
  })
  const [isFocus, setIsFocus] = useState(false)
  const optionsLabels = options.slice(0, 4)

  return (
    <div className={classNames('form_item form_select')}>
      <ReactSelect
        id={exactId}
        instanceId={name}
        onChange={(e) => field.onChange(e.value)}
        onFocus={() => setIsFocus(true)}
        onBlur={(e) => {
          field.onBlur(e)
          setIsFocus(false)
        }}
        value={options.find(item => item.value === field.value) || ''}
        name={field.name}
        placeholder={''}
        options={options}
        classNamePrefix="select"
        noOptionsMessage={() => t('noOptionsMessage')}
        loadingMessage={() => t('loadingMessage')}
        className={classNames(
          'select',
          {
            'b-error': errors?.[field.name],
            'b-has-value': Boolean(field.value) || isFocus
          }
        )}
      />
      {placeholder && <label className='form_placeholder'>{placeholder}</label>}
      {isShowLabel && Array.isArray(options) && options.length !== 0 && (
        <div className='form_labels d-f ai-fs fw-w'>
          {
            optionsLabels.map((item, index) => (
              <div
                key={index}
                onClick={() => field.onChange(item.value)}
                className='form_labels-item'
              >
                {item.label}
              </div>
            ))
          }
        </div>
      )}
    </div>
  )
}

FormSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })),
  isShowLabel: PropTypes.bool,
  control: PropTypes.any,
  name: PropTypes.string,
  exactId: PropTypes.string,
  placeholder: PropTypes.string,
  mask: PropTypes.string,
  validation: PropTypes.object,
  errors: PropTypes.object
}