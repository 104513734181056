'use client'
import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from '@/app/components/Ui/Icon'
import Link from '@/app/components/Ui/Link'

import { handleScrollToElement } from '@/app/utils/handleScrollToElement'

import styles from './PaginationBySections.module.scss'

// let timeLink

export default function PaginationBySections({ data }) {
  const [activeLink, setActiveLink] = useState(data?.items?.[0])
  const [isOpenList, setIsOpenList] = useState(false)
  const linksRef = useRef([])

  const handlerScroll = () => {
    const offsetTop = window.pageYOffset
    linksRef.current.forEach((item, index) => {
      const id = item.getAttribute('href').replace('#', '')
      const section = document.getElementById(id)
      if (section) {
        if (offsetTop >= section.offsetTop - 125) {
          setActiveLink(data?.items?.[index] || activeLink)
        }
      }
    })
  }

  const toggleOpenList = () => {
    setIsOpenList(!isOpenList)
  }

  const onClickLink = (e, id) => {
    e.preventDefault()
    setIsOpenList(false)
    const section = document.querySelector(id)
    handleScrollToElement(section)
  }

  useEffect(() => {
    window.addEventListener('scroll', handlerScroll)
    return () => {
      window.removeEventListener('scroll', handlerScroll)
    }
  }, [])

  return (
    <div className={styles['pagination-by-sections']} id='pagination-by-sections'>
      <div className='wr'>
        <div className={classNames(styles['pagination-by-sections_wrapper'], 'd-f ai-c jc-sb')}>
          <div className={classNames(
            styles['pagination-by-sections_content'],
            {
              [[styles['b-active']]]: isOpenList
            }
          )}
          >
            <div onClick={toggleOpenList} className={styles['pagination-by-sections_active-link']}>
              {activeLink?.name}
            </div>
            <div className={styles['pagination-by-sections_inner']}>
              <ul className={classNames(styles['pagination-by-sections_list'], 'd-f ai-c fw-w')}>
                {Array.isArray(data?.items) && data.items.map((item, index) => (
                  <li key={item.id} className={styles['pagination-by-sections_list-item']}>
                    <a
                      href={`#${item.id}`}
                      ref={el => linksRef.current[index] = el}
                      onClick={(e) => onClickLink(e, `#${item.id}`)}
                      className={classNames(
                        styles['pagination-by-sections_list-link'],
                        {
                          [[styles['b-active']]]: item.id === activeLink?.id
                        }
                      )}>
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
              {data?.phone && (
                <a
                  className={classNames(styles['pagination-by-sections_phone'], 'd-f ai-c ')}
                  href={data?.type === 'email' ? `mailto:${data.phone}` : `tel:${data.phone}`}
                  target="_blank"
                >
                  {data?.type === 'email' ? (
                    <Icon
                      name="email"
                      width={14}
                      height={14}
                      viewBox="0 0 24 24"
                      className={classNames(styles['pagination-by-sections_phone-icon'], styles['b-color-blue'])}
                    />
                  ) : (
                    <Icon
                      name="phone"
                      width={14}
                      height={14}
                      viewBox="0 0 14 14"
                      className={styles['pagination-by-sections_phone-icon']}
                    />
                  )}
                  {data.phone}
                </a>
              )}
            </div>
          </div>
          <div className={classNames(styles['pagination-by-sections_action'], 'd-f ai-c')}>
            {data?.phone && (
              <a
                className={classNames(styles['pagination-by-sections_phone'], 'd-f ai-c ')}
                href={data?.type === 'email' ? `mailto:${data.phone}` : `tel:${data.phone}`}
                target="_blank"
              >
                {data?.type === 'email' ? (
                  <Icon
                    name="email"
                    width={14}
                    height={14}
                    viewBox="0 0 24 24"
                    className={classNames(styles['pagination-by-sections_phone-icon'], styles['b-color-blue'])}
                  />
                ) : (
                  <Icon
                    name="phone"
                    width={14}
                    height={14}
                    viewBox="0 0 14 14"
                    className={styles['pagination-by-sections_phone-icon']}
                  />
                )}
                {data.phone}
              </a>
            )}
            {data?.linkText && data?.link && (
              <Link
                href={data?.link}
                id="pagination-by-sections-btn"
                className={classNames(
                  'btn',
                  {
                    'b-hide-mobile': data?.hideLinkOnMobile
                  }
                )}
              >
                {data?.linkText}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div >
  )
}

PaginationBySections.propTypes = {
  data: PropTypes.shape({
    phone: PropTypes.string,
    type: PropTypes.oneOf(['phone', 'email']),
    hideLinkOnMobile: PropTypes.bool,
    linkText: PropTypes.string,
    link: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    }))
  })
}