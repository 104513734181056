'use client'
import { useEffect, useRef } from 'react'

export default function useEventInitializer(callback) {
  const calledRef = useRef(false)

  useEffect(() => {
    const events = ['mousemove', 'focus', 'scroll', 'touchstart']

    function initEventHandler() {
      if (calledRef.current) return
      calledRef.current = true
      events.forEach(event => {
        window.removeEventListener(event, initEventHandler)
      })
      callback?.()
    }
    events.forEach(event => {
      const passive = event === "scroll" || event === "touchstart"
      window.addEventListener(event, initEventHandler, { passive, once: true })
    })
    return () => {
      events.forEach(event => {
        window.removeEventListener(event, initEventHandler)
      })
    }
  }, [callback])
}
