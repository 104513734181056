'use client'
import { useId } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ReactInputMask from 'react-input-mask'
import { useController } from 'react-hook-form'

export default function FormInputMask({
  name,
  exactId,
  validation = {},
  control,
  errors = {},
  placeholder,
  mask = '+38 (099) 999 99 99'
}) {
  const uniqueId = useId()
  const {
    field
  } = useController({
    name,
    control,
    rules: { ...validation }
  })

  const getId = () => {
    return exactId || `form-${name}-${uniqueId}`
  }

  return (
    <div className='form_item'>
      <ReactInputMask
        onChange={field.onChange}
        onBlur={field.onBlur}
        value={field.value || ''}
        name={field.name}
        mask={mask}
        alwaysShowMask={false}
        placeholder={placeholder}
        id={getId()}
        className={classNames(
          'input',
          {
            'b-error': errors?.[field.name]
          }
        )}
      />
      {placeholder && <label htmlFor={getId()} className='form_placeholder'>{placeholder}</label>}
    </div>
  )
}

FormInputMask.propTypes = {
  control: PropTypes.any,
  name: PropTypes.string,
  exactId: PropTypes.string,
  placeholder: PropTypes.string,
  mask: PropTypes.string,
  validation: PropTypes.object,
  errors: PropTypes.object
}