"use client"
import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import { CSSTransition } from "react-transition-group"
import FormModal from "@/app/components/Modals/FormModal"
import styles from "./PackagesProducts.module.scss"
import PackagesInfo from "./components/PackagesInfo"
import PackagesBlock from "./components/PackagesBlock"

let script

export default function PackagesProducts({
  background,
  title,
  infoBlock,
  blocks,
  attributes,
  lang,
  formTitle,
  formText
}) {
  const contentRef = useRef(null)
  const aiwaRef = useRef(null)
  const [formBuilderUID, setFormBuilderUID] = useState('')
  const hasRecommendText = blocks?.some?.(i => !!i?.head?.headText) || false

  const setCellHeight = () => {
    const items = Array.from(contentRef.current?.children || [])
    items.forEach((element) => {
      const allItems = element.querySelectorAll(".js-packages-item")
      allItems.forEach((item) => {
        item.style.minHeight = "1px"
        item.style.height = "auto"
      })
    })
    if (!contentRef.current || window.innerWidth <= 1024) return
    const maxHeights = []
    items.forEach((element) => {
      const allItems = element.querySelectorAll(".js-packages-item")
      allItems.forEach((item, index) => {
        const height = item.offsetHeight
        if (height > maxHeights?.[index] || !maxHeights?.[index]) {
          maxHeights[index] = Math.ceil(height)
        }
      })
    })
    items.forEach((element) => {
      const allItems = element.querySelectorAll(".js-packages-item")
      allItems.forEach((item, index) => {
        item.style.height = maxHeights?.[index]
          ? `${maxHeights?.[index] + 1}px`
          : "auto"
      })
    })
  }

  useEffect(() => {
    setCellHeight()
    if (!contentRef.current) return
    const resizeObserver = new ResizeObserver(() => {
      setCellHeight()
    })
    resizeObserver.observe(contentRef.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const removeAiwa = () => {
    if (document.head.contains(script)) {
      document.head.removeChild(script)
    }
  }

  const initAiwa = () => {
    removeAiwa()
    script = document.createElement("script")
    script.src = `https://js.aiwa.in.ua/v3/?timestamp=${new Date().getTime()}`
    script.type = "text/javascript"
    script.id = "aiwa-loader"
    script.async = true
    script.onload = () => {
      aiwaRef.current = AUGE.AIWA({
        domain: 'zVGdGR8Kej',
        terminal: 'yoAlLRdD75',
        locale: lang === 'ua' ? 'uk' : lang,
        view: {
          styles: {
            vars: {
              '--aiwa-color-primary': '#ff9800'
            }
          }
        }
      })
      aiwaRef.current.build()
      setCellHeight()
    }
    document.head.appendChild(script)
  }

  useEffect(() => {
    const hasAiwa = blocks?.some((i) => (
      i?.head?.button?.startsWith('aiwaType')
      || i?.bottom?.button?.startsWith('aiwaType')
    ))
    if (!hasAiwa) return
    initAiwa()
    return () => {
      removeAiwa()
    }
  }, [blocks])

  useEffect(() => {
    if (!aiwaRef.current) return
    aiwaRef.current?.build?.()
  }, [formBuilderUID])

  const onOpenFormModal = (value) => {
    if (!value) return
    setFormBuilderUID(value)
  }

  return (
    <div
      className={classNames(
        styles["packages"],
        styles[`bg-${background || "default"}`],
        `${attributes?.class || ""}`
      )}
      id={attributes?.id}
    >
      <div className={classNames(styles["packages-wrapper"], "wr")}>
        <h2 className={styles["packages-title"]}>
          {title}
        </h2>
        <div
          ref={contentRef}
          className={classNames(styles["packages-content"], "d-f ai-s")}
        >
          {!!infoBlock?.length && (
            <PackagesInfo data={infoBlock} />
          )}
          {!!blocks?.length && (
            blocks.map((item, index) => (
              <PackagesBlock
                key={index}
                head={item.head}
                bottom={item.bottom}
                list={item.list}
                infoData={infoBlock}
                hasRecommendText={hasRecommendText}
                background={background}
                onOpenFormModal={onOpenFormModal}
              />
            ))
          )}
        </div>
      </div>
      <CSSTransition
        in={!!formBuilderUID}
        timeout={300}
        unmountOnExit
        classNames="fade"
      >
        <FormModal
          onCloseModal={() => setFormBuilderUID('')}
          formBuilderUID={formBuilderUID}
          title={formTitle}
          titleTag='h4'
          text={formText}
          handleAfterSubmit={() => setFormBuilderUID('')}
          redirect={'/thanks'}
        />
      </CSSTransition>
    </div>
  )
}

PackagesProducts.propTypes = {
  background: PropTypes.string,
  title: PropTypes.string,
  infoBlock: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    tooltipText: PropTypes.string,
    text: PropTypes.string
  })),
  blocks: PropTypes.arrayOf(PropTypes.shape({
    head: PropTypes.shape({
      name: PropTypes.string,
      text: PropTypes.string,
      button: PropTypes.string,
      buttonText: PropTypes.string,
      recommend: PropTypes.bool,
      textUnderButton: PropTypes.string,
      headText: PropTypes.string,
      price: PropTypes.string
    }),
    list: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string
    })),
    bottom: PropTypes.shape({
      text: PropTypes.string,
      button: PropTypes.string,
      buttonText: PropTypes.string,
      price: PropTypes.string
    })
  })),
  attributes: PropTypes.shape({
    class: PropTypes.string,
    id: PropTypes.string,
  })
}
