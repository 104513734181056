'use client'
// import Image from 'next/image'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Link from '@/app/components/Ui/Link'

import styles from './NewsBlock.module.scss'

export default function NewsBlock({
  link,
  previewImage,
  text,
  categoryName,
  date
}) {
  return (
    <div className={styles['news-block']}>
      <Link href={link} className={styles['news-block_image']}>
        <span className={styles['news-block_image-inner']}>
        {previewImage && (
          // <Image
          //   src={previewImage}
          //   alt={text}
          //   width={0}
          //   height={0}
          //   sizes="100vw"
          //   style={{ width: '100%', height: '100%' }}
          // />
          <img
            src={previewImage}
            alt={text}
            loading='lazy'
          />
        )}
        </span>
      </Link>
      <div className={classNames(styles['news-block_info'], 'd-f ai-c')}>
        {categoryName && (
          <div className={styles['news-block_categories']}>
            {categoryName}
          </div>
        )}
        <div className={styles['news-block_date']}>
          {date}
        </div>
      </div>
      <Link href={link} className={styles['news-block_text']}>
        {text}
      </Link>
    </div>
  )
}

NewsBlock.propTypes = {
  link: PropTypes.string,
  previewImage: PropTypes.string,
  text: PropTypes.string,
  categoryName: PropTypes.string,
  date: PropTypes.string
}