import { clientGraphQLRequest } from "@/app/api/apiClient"

import {
  QueryOffices,
  QueryCountryRegions,
  QueryCities
} from './query'

import {
  mapOfficesData,
  mapCountryRegionsData,
  mapCitiesData
} from "./apiContactsUtils"

const PAGE_SIZE = 100

export const fetchOffices = async (lang = 'uk') => {
  try {
    const variables = {
      locale: lang,
      pagination: {
        page: 1,
        pageSize: PAGE_SIZE
      }
    }
    const res = await clientGraphQLRequest(QueryOffices, variables)
    return mapOfficesData(res)
  } catch (error) {
    console.warn(error, '-> fetchOffices')
  }
}

export const fetchCountryRegions = async (lang = 'uk') => {
  try {
    const variables = {
      locale: lang,
      pagination: {
        page: 1,
        pageSize: PAGE_SIZE
      }
    }
    const res = await clientGraphQLRequest(QueryCountryRegions, variables)
    return mapCountryRegionsData(res)
  } catch (error) {
    console.warn(error, '-> fetchCountryRegions')
  }
}

export const fetchCities = async (lang = 'uk') => {
  try {
    const variables = {
      locale: lang,
      pagination: {
        page: 1,
        pageSize: PAGE_SIZE
      }
    }
    const res = await clientGraphQLRequest(QueryCities, variables)
    return mapCitiesData(res)
  } catch (error) {
    console.warn(error, '-> fetchCities')
  }
}
