'use client'
import { useId } from 'react';
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Icon from '@/app/components/Ui/Icon'
import { Tooltip } from 'react-tooltip'

import styles from './Advantages.module.scss'

export default function Advantages({
  title,
  titleCustomTag,
  description,
  items,
  iconSize,
  columns = 'three',
  background = 'default',
  attributes,
  isWideTitle,
}) {
  const id = useId()
  const TitleComponent = titleCustomTag || 'h2'

  return (
    <div
      className={classNames(
        styles['advantages'],
        [styles[`bg-${background || 'default'}`]],
        [styles[`columns-${columns || 'three'}`]],
        [styles[`b-${iconSize || 'size_64x64'}`]],
        isWideTitle && styles['is-wide-title'],
        `${attributes?.class || ''}`
      )}
      id={attributes.id}
    >
      <div className='wr'>
        <TitleComponent className={classNames(styles['advantages_title'], 'h2')}>{title}</TitleComponent>
        {description && (
          <p
            className={classNames(styles['advantages_descr'], 'ws-pl')}
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
        )}
        {Array.isArray(items) && items.length !== 0 && (
          <div className={classNames(styles['advantages_content'], 'd-f ai-fs fw-w')}>
            {items.map((item, index) => (
              <div key={index} className={styles['advantages_block']}>
                <div className={classNames(styles['advantages_block-icon'], 'd-f ai-c jc-c')}>
                  <img
                    src={item.icon}
                    alt='icon'
                    width={iconSize === 'size_96x96' ? 96 : 64}
                    height={iconSize === 'size_96x96' ? 96 : 64}
                    loading='lazy'
                  />
                </div>
                <div
                  className={classNames(styles['advantages_block-title'], 'h4')}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                {item.text && (
                  <div
                    className={classNames(
                      styles['advantages_block-text']
                    )}
                  >
                    <div
                      className={classNames(
                        styles['advantages_block-text-inner'],
                        'ws-pl'
                      )}
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    />
                    {item.infoTooltip && (
                      <>
                        {' '}
                        <Tooltip
                          className={styles['advantages_tooltip']}
                          id={`advantages-${id}-${index}`}
                          place="bottom-start"
                          content={item.infoTooltip}
                          noArrow
                          openOnClick
                          disableStyleInjection
                        />

                        <span
                          className={styles['advantages_tooltip-toggle']}
                          data-tooltip-id={`advantages-${id}-${index}`}
                        >
                          <Icon
                            name={'info'}
                            width={20}
                            height={20}
                            viewBox="0 0 20 20"
                          />
                        </span>
                      </>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

Advantages.propTypes = {
  title: PropTypes.string,
  titleCustomTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div']),
  description: PropTypes.string,
  columns: PropTypes.string,
  background: PropTypes.oneOf(['blue', 'green']),
  iconSize: PropTypes.oneOf(['size_64x64', 'size_96x96']),
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string,
    infoTooltip: PropTypes.string
  })),
  attributes: PropTypes.shape({
    class: PropTypes.string,
    id: PropTypes.string
  }),
  isWideTitle: PropTypes.bool,
}