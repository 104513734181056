'use client'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import { changeImageSrc } from '@/app/utils/changeImageSrc'

import { handleScrollToElement } from '@/app/utils/handleScrollToElement'

import styles from './Accordion.module.scss'

export default function Accordion({
  title,
  children,
  isOpen,
  onToggleOpen,
  isScrollToBlock = true,
  itemsTitleTag,
}) {

  const onScrollToElement = (el) => {
    if (el && isScrollToBlock) {
      const paginationBySections = document.getElementById('pagination-by-sections')
      const offset = paginationBySections ? paginationBySections.clientHeight : 0
      handleScrollToElement(el.parentNode, offset)
    }
  }

  const CustomTag = itemsTitleTag ? `${itemsTitleTag}` : 'div'

  return (
    <div
      className={classNames(
        styles['accordion'],
        'accordion',
        {
          [styles['b-open']]: isOpen
        }
      )}
      itemScope
      itemProp='mainEntity'
      itemType='https://schema.org/Question'
    >
      <div
        className={classNames(styles['accordion_top'], 'd-f ai-c jc-sb')}
        onClick={onToggleOpen}
      >
        <CustomTag className={classNames(styles['accordion_title'], 'accordion_title')} itemProp='name'>
          {title}
        </CustomTag>
        <button className={styles['accordion_btn']} />
      </div>
      <CSSTransition
        in={isOpen}
        timeout={300}
        onEnter={(e) => {
          e.style.height = `${e.scrollHeight}px`;
          onScrollToElement(e);
        }}
        onExit={(e) => {
          e.style.height = `0px`
        }}
        classNames="accordion-collapse"
      >
        <div className={styles['accordion_content']} itemScope itemProp='acceptedAnswer' itemType='https://schema.org/Answer'>
          {typeof children === 'string' && (
            <div
              className={classNames(styles['accordion_inner'], 'accordion_inner rich-text')}
              dangerouslySetInnerHTML={{ __html: changeImageSrc(children) }}
              itemProp='text'
            />
          )}
          {typeof children === 'object' && (
            <div className={classNames(styles['accordion_inner'], 'accordion_inner')}>
              {children}
            </div>
          )}
        </div>
      </CSSTransition>
    </div>
  )
}

Accordion.propTypes = {
  onToggleOpen: PropTypes.func,
  isOpen: PropTypes.bool,
  isScrollToBlock: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any,
  itemsTitleTag: PropTypes.string,
}