'use client'
import { useEffect, useState } from 'react'
import { useParams } from 'next/navigation'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { fetchFormBuilderData } from '@/app/api/form-builder/apiFormBuilder'
import useModal from '@/app/hooks/useModal'
import FormBuilder from '@/app/components/Ui/FormBuilder'

import styles from './FormModal.module.scss'

export default function FormModal({
  onCloseModal,
  title,
  titleTag = 'h3',
  text,
  formBuilderUID,
  handleAfterSubmit,
  redirect,
}) {
  const params = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [formBuilder, setFormBuilder] = useState()

  const getFormBuilder = async () => {
    try {
      const resForm = await fetchFormBuilderData(formBuilderUID, params.local)
      setFormBuilder(resForm)
    } catch (e) {
      console.warn(e, 'getFormBuilder')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (formBuilderUID) {
      getFormBuilder()
    }
  }, [formBuilderUID])

  useModal()

  return (
    <div className={classNames(styles['form-modal'], 'd-f ai-c js-c')}>
      <div className={classNames(styles['form-modal_wrapper'], !title && styles['empty-title'])}>
        <button
          onClick={onCloseModal}
          className={styles['form-modal_btn-close']}
          type='button'
        />
        {isLoading ? (
          <div className={'d-f ai-c jc-c'}>
            <span className='loader'></span>
          </div>
        ) : (
          <div className={styles['form-modal_content']}>
            {title && (
              <div
                className={
                  classNames(
                    styles['form-modal_title'],
                    titleTag
                  )
                }
              >{title}</div>
            )}
            {text && (
              <div className={styles['form-modal_text']}>{text}</div>
            )}
            <div className={styles['form-modal_form']}>
              {formBuilder && (
                <FormBuilder
                  form={formBuilder}
                  isSubmitFullWidth={true}
                  handleAfterSubmit={handleAfterSubmit}
                  redirect={redirect}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

FormModal.propTypes = {
  onCloseModal: PropTypes.func,
  title: PropTypes.string,
  titleTag: PropTypes.oneOf(['h3', 'h4']),
  text: PropTypes.string,
  formBuilderUID: PropTypes.any,
  handleAfterSubmit: PropTypes.func,
  redirect: PropTypes.string,
}